{
  "name": "jssip",
  "title": "JsSIP",
  "description": "the Javascript SIP library",
  "version": "3.9.1",
  "homepage": "https://jssip.net",
  "contributors": [
    "José Luis Millán <jmillan@aliax.net> (https://github.com/jmillan)",
    "Iñaki Baz Castillo <ibc@aliax.net> (https://inakibaz.me)"
  ],
  "types": "lib/JsSIP.d.ts",
  "main": "lib-es5/JsSIP.js",
  "keywords": [
    "sip",
    "websocket",
    "webrtc",
    "node",
    "browser",
    "library"
  ],
  "license": "MIT",
  "repository": {
    "type": "git",
    "url": "https://github.com/versatica/JsSIP.git"
  },
  "bugs": {
    "url": "https://github.com/versatica/JsSIP/issues"
  },
  "dependencies": {
    "@types/debug": "^4.1.5",
    "@types/node": "^14.14.34",
    "debug": "^4.3.1",
    "events": "^3.3.0",
    "sdp-transform": "^2.14.1"
  },
  "devDependencies": {
    "@babel/core": "^7.13.10",
    "@babel/preset-env": "^7.13.10",
    "ansi-colors": "^3.2.4",
    "browserify": "^16.5.1",
    "eslint": "^5.16.0",
    "fancy-log": "^1.3.3",
    "gulp": "^4.0.2",
    "gulp-babel": "^8.0.0",
    "gulp-eslint": "^5.0.0",
    "gulp-expect-file": "^1.0.2",
    "gulp-header": "^2.0.9",
    "gulp-nodeunit-runner": "^0.2.2",
    "gulp-plumber": "^1.2.1",
    "gulp-rename": "^1.4.0",
    "gulp-uglify-es": "^1.0.4",
    "pegjs": "^0.7.0",
    "vinyl-buffer": "^1.0.1",
    "vinyl-source-stream": "^2.0.0"
  },
  "scripts": {
    "lint": "gulp lint",
    "test": "gulp test",
    "prepublishOnly": "gulp babel"
  }
}
