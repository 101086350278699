import { type TransferIntegration } from '../../types';
import { sendAnaliticsMessageCallbackType, TransferController } from './TransferBase';
import { DaktelaTransferController } from './implementations/daktela/DaktelaTransferController';

export const getTransferController = (transferIntegration: TransferIntegration, sendAnaliticsMessageCallback: sendAnaliticsMessageCallbackType): TransferController | undefined => {
  
    switch(transferIntegration.provider) {
       case "daktela": return new DaktelaTransferController(transferIntegration.accessToken, sendAnaliticsMessageCallback, {
        baseUrl: transferIntegration.baseUrl
       })
    }
  }