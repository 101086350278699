import MChannelsProtocolMchatAdapter from '../protocol-mchat-adapters/MChannelsProtocolMchatAdapter';
import type { DialogConnector } from '../dialog-connectors/DialogConnector';
import {AnalyticMessageOptions, type ProtocolCommands, ProtocolManager, ProtocolManagerSendOptions, ProtocolManagerSendProps } from './ProtocolManager';
import { HTMLTextPreprocessor } from '../utils/TextPreprocessor';
import MChannelsRestDialogConnector from '../dialog-connectors/MChannelsRestDialogConnector';
import { type AnalyticMessage, type FeedbackInputFormat } from '../types';

interface mChannelsRequest {
  input: {
    text?: string;
    event?: string;
    feedback?: {
      rating?: number | 'up' | 'down';
      comment?: {
        text: string;
      };
    };
  };
  context: object;
  output: object;
}

interface Context {
    session_id: string;
    conversation_id: string;
    action?: {
      command: 'Transfer' | 'ProceedWithTransfer' | 'ConfirmUserInput';
      params?: object;
    };
}

interface mChannelsResponse {
  input: {
    text?: string;
    event?: string;
  };
  context: Context;
  output: object;
}

class MChannelsProtocolManager extends ProtocolManager {
  sendAnaliticalMessage(analyticalMessage: AnalyticMessage, options?: AnalyticMessageOptions): void {
    throw new Error('Method not implemented.');
  }
  context: Partial<Context>;
  eventToSend: string | undefined;
  _analyticMessages: AnalyticMessage[];

  constructor(dialogConnector: DialogConnector) {
    super(
      dialogConnector,
      new MChannelsProtocolMchatAdapter(new HTMLTextPreprocessor()),
    );

    this.context = {};
    this._analyticMessages = [];

    this.eventToSend = undefined;
  }

  async send(
    props: ProtocolManagerSendProps = {},
  ) {
    const { inputText, extraPayload = {}, extraInput = {}} = props;
    let {options} = props;

    if(!options) {
      options = {
        includeAnalyticMessages: true
      }
    }

    delete this.context.action;

    const payload: mChannelsRequest = {
      input: {
        ...extraInput,
      },
      context: this.context,
      output: {},
      ...extraPayload,
    };

    if(inputText) {
      Object.assign(payload.input, {
        text: inputText
      })
    }

    if(options.includeAnalyticMessages && this._analyticMessages.length > 0){
      Object.assign(payload.input, this._assemble_analytic_events_payload())
      this._analyticMessages = []
    }

    if (typeof this.eventToSend !== 'undefined') {
      payload.input.event = this.eventToSend;
    }

    await this._dialogConnector.send(payload);
  }

  sendFeedback(feedbackResponse: FeedbackInputFormat) {

    const payload: mChannelsRequest = {
      input: {
        feedback: feedbackResponse,
      },
      context: this.context,
      output: {},
    };

    if (typeof this.eventToSend !== 'undefined') {
      payload.input.event = this.eventToSend;
    }

    this._dialogConnector.send(payload);
  }

  _assemble_analytic_events_payload() {
    return {
      analytic_events: [...this._analyticMessages]
    }
  }

  async sendAnalyticMessage(analyticMessage: AnalyticMessage, options?: AnalyticMessageOptions) {
    this._analyticMessages.push(analyticMessage);
    if(options?.postpone) {
      return
    }
    return await this.send()
  }

  refreshSession() {
    this._dialogConnector.refreshSession();
  }

  _sendEvent(name: string, extraPayload = {}) {
    const payload = {
      input: {
        event: name,
      },
      context: this.context,
      output: {},
      ...extraPayload,
    };

    this._dialogConnector.send(payload);
  }

  getCommandProcessors(...args: string[]): {
    [key in keyof typeof ProtocolCommands]: () => void;
  } {
    return {
      debug: () => {
        if (args[0] === 'on') {
          this.eventToSend = 'DebugOn';
        } else if (args[0] === 'off') {
          this.eventToSend = 'DebugOff';
        }
      },
    };
  }

  handleResponse(response: mChannelsResponse) {
    if (!response) {
      return;
    }

    this.context = response.context;

    if (typeof this.sessionId === 'undefined') {
      // TODO - As soon as Teresa start supporting the session_id do not use conversation_id as identifier
      if (this._dialogConnector instanceof MChannelsRestDialogConnector) {
        this.sessionId = response.context?.conversation_id;
      } else {
        this.sessionId = response.context?.session_id;
      }
    }

    const [mChatResponse, mChatInstructions] =
      this._protocolMchatAdapter.processResponse(response);

    this.emit('response', mChatResponse, mChatInstructions, {
      input: response.input?.text,
      event: response.input?.event,
    });

    if (response.context?.action?.command === 'Transfer') {
      this.emit('transfer', {
        name: 'transfer',
        command: 'verify_availability',
      });
    } else if (response.context?.action?.command === 'ProceedWithTransfer') {
      this.emit('transfer', {
        name: 'transfer',
        command: 'initiate',
        data: response.context?.action?.params,
      });
    }

    delete this.context.action;
  }

  reset() {
    this.context = {};
  }

  async reconnect(sessionId?: string) {
    if (this._dialogConnector instanceof MChannelsRestDialogConnector) {
      this.context = {
        conversation_id: sessionId,
      };
      return await this._dialogConnector.connect();
    } else {
      return await this._dialogConnector.connect(sessionId);
    }
  }

  _processDebugCommand(mode: 'on' | 'off') {}
}

export default MChannelsProtocolManager;
