import dayjs from 'dayjs';
import type {
  TextChatBlock,
  HyperlinksGroupChatBlock,
  Direction,
  ChatBlock,
} from './../types';

export const getMessageChatBlock = (
  message: string,
  direction: Direction = 'in',
  timestamp?: string,
): TextChatBlock => ({
  type: 'message',
  direction,
  timestamp: timestamp ?? dayjs.utc().format(),
  content: {
    text: message,
  },
});

export const assembleChatBlocksFromUserErrorMessage = (
  userErrorMessage: string,
): ChatBlock[] => {
  if (!userErrorMessage) {
    return [];
  }
  const chatBlocks = [];
  const messageParts = userErrorMessage.split('|');
  for (const messagePart of messageParts) {
    chatBlocks.push(getMessageChatBlock(messagePart));
  }

  return chatBlocks;
};

export const groupHyperlinks = (chatBlocks: ChatBlock[]): ChatBlock[] => {
  const updatedChatBlocks = [];
  let hyperlinksGroup: HyperlinksGroupChatBlock | undefined;

  for (let i = 0; i < chatBlocks.length; i++) {
    const chatBlock = chatBlocks[i];
    const nextChatBlock =
      i < chatBlocks.length - 1 ? chatBlocks[i + 1] : undefined;
    if (
      chatBlock.type !== 'hyperlink' ||
      (nextChatBlock?.type !== 'hyperlink' && !hyperlinksGroup)
    ) {
      if (hyperlinksGroup) {
        updatedChatBlocks.push(hyperlinksGroup);
        hyperlinksGroup = undefined;
      }
      updatedChatBlocks.push(chatBlock);
    } else {
      if (hyperlinksGroup) {
        hyperlinksGroup.content.hyperlinks.push(chatBlock.content);
      } else {
        hyperlinksGroup = {
          type: 'hyperlinks-group',
          direction: 'in',
          content: {
            hyperlinks: [chatBlock.content],
          },
        };
      }
    }
  }

  if (hyperlinksGroup) {
    updatedChatBlocks.push(hyperlinksGroup);
  }

  return updatedChatBlocks;
};
