import classNames from 'classnames';
import { type FC } from 'react';
import { type ImageChatBlockContent, type Direction } from '../../types';

interface ImageMessageProps extends ImageChatBlockContent {
  direction: Direction;
}

export const ImageMessage: FC<ImageMessageProps> = ({ direction, source }) => {
  return (
    <div
      className={classNames('mchat-message', {
        'mchat-message--in': direction === 'in',
        'mchat-message--out': direction === 'out',
      })}
    >
      <img className='mchat-bubble--in mchat-image' src={source} alt=''></img>
    </div>
  );
};
