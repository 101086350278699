import { type FC, useState, useRef, useCallback } from 'react';
import { type FeedbackChatBlockContent } from '../../types';
import useStore from '../../state';
import classNames from 'classnames';
import { Button, IconButton } from '../common/Buttons';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons/faCheckDouble';

export interface FeedbackValueProps {
  onClick?: (value: any) => void;
  value: number | string;
  selectedValue: number | string | null;
  kind: 'stars' | 'thumbs' | 'numeric_scale';
  disabled?: boolean;
}

const FeedbackValue: FC<FeedbackValueProps> = (props) => {
  const {
    selectedValue,
    value,
    kind,
    disabled,
    onClick = (value) => {},
  } = props;

  const classes = classNames('mchat-feedback__value', {
    'mchat-feedback__value--star': kind === 'stars',
    'mchat-feedback__value--thumb': kind === 'thumbs',
    'mchat-feedback__value--numeric-scale': kind === 'numeric_scale',
    'mchat-feedback__value--selected': selectedValue === value,
  });

  return (
    // eslint-disable-next-line
    <div onClick={() => onClick(value)} className={classes}>
      {kind === 'stars' &&
        (selectedValue != null && selectedValue >= value ? '★' : '☆')}
      {kind === 'thumbs' &&
        value === 0 &&
        (selectedValue == null || selectedValue === value) &&
        '👍'}
      {kind === 'thumbs' &&
        value === 1 &&
        (selectedValue == null || selectedValue === value) &&
        '👎'}
      {kind === 'numeric_scale' && <Button disabled={disabled}>{value}</Button>}
    </div>
  );
};

interface FeedbackMessageProps extends FeedbackChatBlockContent {
  isEnabled?: boolean;
}

export const FeedbackMessage: FC<FeedbackMessageProps> = (props) => {
  const [value, setValue] = useState<string | number | null>(null);
  const [isComment, setComment] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [confirmed, confirm] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const sendFeedback = useStore((state) => state.actions.sendFeedback);

  const enabled = props.isEnabled;

  const sendTextFeedback = () => {
    sendFeedback({
      rating: value as any,
      comment: {
        text: message,
      },
    });
    confirm(true);
  };

  const values = [];
  switch (props.kind) {
    case 'stars':
      for (let i = 1; i <= (props.options?.max ?? 5); i++) {
        values.push(i);
      }
      break;
    case 'thumbs':
      values.push(0);
      values.push(1);
      break;
    case 'numeric_scale':
      for (
        let i = props.options?.min ?? 5;
        i <= (props.options?.max ?? 5);
        i += props.options?.step ?? 1
      ) {
        if (values.length >= 100) {
          // prevent large loops
          break;
        }
        values.push(i);
      }
      break;
  }

  const className = classNames('mchat-message', 'mchat-feedback-message', {
    'mchat-feedback-message--compact': props.compact && !isComment,
    'mchat-feedback-message--comment': isComment,
  });

  const valuesClassName = classNames('mchat-feedback__values', {
    'mchat-feedback__values--editable': enabled && !confirmed,
    'mchat-feedback__values--selected': value != null,
  });

  const handleValue = useCallback(
    (newValue: any) => {
      if (!enabled) {
        return;
      }
      setValue(newValue);

      if (isComment) {
        return;
      }

      const showComment = Boolean(
        props.feedbackComment &&
          (newValue as number) <=
            (props.feedbackComment.requestedUpTo ?? 100000),
      );
      setComment(showComment);

      if (!showComment) {
        sendFeedback({
          rating: newValue,
        });
        confirm(true);
      }

      setTimeout(() => {
        if (inputRef?.current) {
          inputRef?.current.focus();
        }
        if (divRef?.current) {
          divRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
    },
    [
      value,
      enabled,
      isComment,
      sendFeedback,
      props.feedbackComment,
      inputRef,
      divRef?.current,
    ],
  );

  return (
    <div ref={divRef} className={className}>
      {!props.compact && (
        <>
          <div className='mchat-feedback__title'>{props.title}</div>
          <div className='mchat-feedback__text'>{props.text}</div>
        </>
      )}

      <div className={valuesClassName}>
        {values.map((i) => {
          return (
            <FeedbackValue
              key={i}
              value={i}
              kind={props.kind}
              selectedValue={value}
              onClick={handleValue}
              disabled={confirmed}
            />
          );
        })}
      </div>

      {isComment && (
        <div className='mchat-feedback__comment'>
          {props.feedbackComment?.title && (
            <div className='mchat-feedback__comment-title'>
              {props.feedbackComment?.title}
            </div>
          )}
          <input
            ref={inputRef}
            value={message}
            disabled={!enabled || confirmed}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter' && message.trim() !== '') {
                sendTextFeedback();
                e?.currentTarget?.blur();
              }
            }}
            className='mchat-input-section__input'
            type='text'
            // placeholder={props.feedbackComment?.title}
          />
          <IconButton
            disabled={!enabled || confirmed}
            selected={confirmed}
            onClick={sendTextFeedback}
            icon={!confirmed ? faCheck : faCheckDouble}
          >
            Potvrdit
          </IconButton>
        </div>
      )}
    </div>
  );
};
