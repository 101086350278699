import React from 'react';
import { useCallback, type FC, type SyntheticEvent } from 'react';

import useOnLinkClick from '../../../hooks/useOnLinkClick';
import { type LinkButtonProps, LinkButton } from '../Buttons';
import type { MChatEvents, OnClickAction } from '../../../types';

interface HyperlinkButtonProps extends LinkButtonProps {
  url: string;
}

export const HyperlinkButton: FC<HyperlinkButtonProps> = ({
  url,
  children,
  ...rest
}) => {

  return (
    <LinkButton url={url}  {...rest}>
      {children}
    </LinkButton>
  );
};
