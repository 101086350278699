import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import React, {
  type FC,
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { AVAILABLE_COMMANDS } from '../config';
import useStore from '../state';
import { AudioState, type ChatInputActions } from '../types';

const COMMAND_IDENTIFIER = '/';

const AudioLoader: FC<{
  audioState: AudioState;
}> = ({ audioState }) => {
  const getAudioLoaderText = () => {
    switch (audioState) {
      case AudioState.Initiating:
        return 'Initializing';
      case AudioState.Listening:
        return 'Listening';
      case AudioState.Processing:
        return 'Processing';
      case AudioState.Speaking:
        return 'Speaking';
      default:
        return '';
    }
  };

  return (
    <div className='mchat-audio-loader'>
      <span>{getAudioLoaderText()}</span>
    </div>
  );
};

const MicrophoneIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='2.5em'
    height='2.5em'
    fill='white'
    stroke='white'
    strokeWidth='0'
    viewBox='0 0 16 16'
  >
    <path d='M5 3a3 3 0 016 0v5a3 3 0 01-6 0V3z'></path>
    <path d='M3.5 6.5A.5.5 0 014 7v1a4 4 0 008 0V7a.5.5 0 011 0v1a5 5 0 01-4.5 4.975V15h3a.5.5 0 010 1h-7a.5.5 0 010-1h3v-2.025A5 5 0 013 8V7a.5.5 0 01.5-.5z'></path>
  </svg>
);

const PauseIcon = () => {
  return (
    <svg
      width='2.5em'
      height='2.5em'
      fill='white'
      stroke='white'
      strokeWidth='0'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z' />
    </svg>
  );
};

const SpeakIcon = () => (
  <svg
    stroke='39393a'
    fill='39393a'
    strokeWidth='0'
    viewBox='0 0 24 24'
    aria-hidden='true'
    height='2.5em'
    width='2.5em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M13.5 4.06c0-1.336-1.616-2.005-2.56-1.06l-4.5 4.5H4.508c-1.141 0-2.318.664-2.66 1.905A9.76 9.76 0 001.5 12c0 .898.121 1.768.35 2.595.341 1.24 1.518 1.905 2.659 1.905h1.93l4.5 4.5c.945.945 2.561.276 2.561-1.06V4.06zM18.584 5.106a.75.75 0 011.06 0c3.808 3.807 3.808 9.98 0 13.788a.75.75 0 11-1.06-1.06 8.25 8.25 0 000-11.668.75.75 0 010-1.06z'></path>
    <path d='M15.932 7.757a.75.75 0 011.061 0 6 6 0 010 8.486.75.75 0 01-1.06-1.061 4.5 4.5 0 000-6.364.75.75 0 011.06-1.061z'></path>
  </svg>
);

const SendIcon = () => {
  return (
    <svg
      width='2.5em'
      height='2.5em'
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 495.003 495.003'
    >
      <g id='XMLID_51_'>
        <path
          id='XMLID_53_'
          d='M164.711,456.687c0,2.966,1.647,5.686,4.266,7.072c2.617,1.385,5.799,1.207,8.245-0.468l55.09-37.616
		l-67.6-32.22V456.687z'
        />
        <path
          id='XMLID_52_'
          d='M492.431,32.443c-1.513-1.395-3.466-2.125-5.44-2.125c-1.19,0-2.377,0.264-3.5,0.816L7.905,264.422
		c-4.861,2.389-7.937,7.353-7.904,12.783c0.033,5.423,3.161,10.353,8.057,12.689l125.342,59.724l250.62-205.99L164.455,364.414
		l156.145,74.4c1.918,0.919,4.012,1.376,6.084,1.376c1.768,0,3.519-0.322,5.186-0.977c3.637-1.438,6.527-4.318,7.97-7.956
		L494.436,41.257C495.66,38.188,494.862,34.679,492.431,32.443z'
        />
      </g>
    </svg>
  );
};

interface ChatInputProps {
  onMessage: (message: string) => void;
  audioState?: AudioState;
  onAudioButtonClick: (audioState?: AudioState) => void;
  maxCharacters?: number;
}

// eslint-disable-next-line react/display-name
export const ChatInput = forwardRef<ChatInputActions, ChatInputProps>(
  (
    { onMessage, audioState, onAudioButtonClick, maxCharacters = 100000000000 },
    ref,
  ) => {
    const [message, setMessage] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const processCommand = useStore((state) => state.actions.processCommand);
    const mchatConfig = useStore((state) => state.currentConfig);
    const activatedUserPromptPanel = useStore(
      (state) => state.activatedUserPromptPanel,
    );
    const ChatInputTextareaAttributes =
      useStore(
        (state) => state.componentsCustomization?.ChatInputTextareaAttributes,
      ) ?? {};

    const waitingForResponse = useStore((state) => state.waitingForResponse);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [characterCount, setCharacterCount] = useState(0);

    const { t } = useTranslation();

    useImperativeHandle(
      ref,
      () => {
        return {
          setUserInput(text: string) {
            setMessage(text);
            setCharacterCount(text.length);
          },
          focus() {
            textareaRef.current?.focus();
            inputRef.current?.focus();
          },
        };
      },
      [],
    );

    useEffect(() => {
      setButtonDisabled(waitingForResponse || characterCount > maxCharacters);
    }, [waitingForResponse, characterCount, setButtonDisabled]);

    const preventFocus = useCallback((event: React.MouseEvent) => {
      event.preventDefault();
    }, []);

    const handleSendMessage = useCallback(() => {
      if (message.trim() === '' || buttonDisabled) {
        return;
      }

      const command = tryToParseCommandInput(message);

      if (!command) {
        onMessage(message);
        if (
          activatedUserPromptPanel?.type ===
          'UserPromptMessageConfirmationPanel'
        ) {
          return;
        }
        setMessage('');
        setCharacterCount(0);
      } else {
        const [commandName, commandArgs] = command;
        setMessage('');
        processCommand(commandName, ...commandArgs);
      }
    }, [message, buttonDisabled, setMessage, onMessage]);

    const handleRecordButtonClick = () => {
      onAudioButtonClick(audioState);
    };

    const charactersDiff = characterCount - maxCharacters;

    const handleUpdate = useCallback(
      (
        event:
          | React.ChangeEvent<HTMLInputElement>
          | React.ChangeEvent<HTMLTextAreaElement>,
      ) => {
        setMessage(event.target.value);
        setCharacterCount(event.target.value.length);
      },
      [setMessage, setCharacterCount],
    );

    const renderInput = useCallback(() => {
      switch (mchatConfig.inputType) {
        case 'one-line':
          return (
            <input
              ref={inputRef}
              value={message}
              onChange={handleUpdate}
              onKeyUp={(e) => {
                if (e.key === 'Enter' && message.trim() !== '') {
                  handleSendMessage();
                }
              }}
              className='mchat-input-section__input'
              type='text'
              placeholder={t('input__placeholder')}
            />
          );

        case 'multi-line':
          return (
            <textarea
              ref={textareaRef}
              rows={3}
              value={message}
              onChange={handleUpdate}
              onKeyDown={(e) => {
                if (
                  document.activeElement === e.target &&
                  e.key === 'Enter' &&
                  !e.shiftKey &&
                  message.trim() !== ''
                ) {
                  e?.preventDefault();
                  handleSendMessage();
                }
              }}
              className='mchat-input-section__input mchat-input-section__input--multiline'
              placeholder={t('input__placeholder')}
              {...ChatInputTextareaAttributes}
            />
          );
      }
    }, [
      inputRef,
      message,
      handleUpdate,
      handleSendMessage,
      mchatConfig,
      ChatInputTextareaAttributes,
    ]);

    return (
      <div className='mchat-input-section'>
        {audioState !== AudioState.Active && audioState !== undefined ? (
          <AudioLoader audioState={audioState} />
        ) : (
          renderInput()
        )}
        {message.trim() === '' && audioState !== undefined ? (
          <button
            tabIndex={-1}
            type='button'
            onClick={handleRecordButtonClick}
            title=''
            disabled={buttonDisabled}
            className={classNames('mchat-input-section__button', {
              'mchat-input-section__button--audio-active':
                audioState !== AudioState.Active,
              'mchat-input-section__button--audio-pulsing':
                AudioState.Listening === audioState ||
                AudioState.Speaking === audioState,
            })}
          >
            {audioState === AudioState.Initiating ? (
              <MicrophoneIcon />
            ) : audioState === AudioState.Listening ? (
              <PauseIcon />
            ) : audioState === AudioState.Speaking ? (
              <SpeakIcon />
            ) : (
              <MicrophoneIcon />
            )}
          </button>
        ) : (
          <button
            tabIndex={-1}
            type='button'
            contentEditable={false}
            onMouseDown={preventFocus}
            onClick={handleSendMessage}
            title='Odeslat'
            disabled={buttonDisabled}
            className={classNames('mchat-input-section__button', {
              'mchat-input-section__button--input-character-counter':
                buttonDisabled && charactersDiff > 0,
            })}
          >
            {buttonDisabled && charactersDiff > 0 ? (
              <div className='mchat-input-section__character-counter'>
                -{charactersDiff}
              </div>
            ) : (
              <SendIcon />
            )}
          </button>
        )}
      </div>
    );
  },
);

const tryToParseCommandInput = (
  input: string,
): [string, string[]] | undefined => {
  if (!input.startsWith(COMMAND_IDENTIFIER)) return;

  const commandParts = input.split(/\s/);
  if (!commandParts || commandParts.length === 1) {
    return;
  }

  const commandName = commandParts[0].slice(1, commandParts[0].length);

  if (commandName && !AVAILABLE_COMMANDS.includes(commandName)) return;

  let args: string[] = [];
  let code = '';

  if (commandParts.length > 2) {
    args = commandParts.slice(1, commandParts.length - 1);
    code = commandParts[commandParts.length - 1];
  } else if (commandParts.length === 2) {
    code = commandParts[commandParts.length - 1];
    args = [];
  }

  if (code === getCurrentDate()) {
    return [commandName, args];
  }
};

const getCurrentDate = (): string => {
  const objectDate = new Date();

  const day = objectDate.getDate();
  const month = objectDate.getMonth() + 1;
  const year = String(objectDate.getFullYear()).slice(-2);
  const firstPart = day + month;

  return `${firstPart < 10 ? '0' : ''}${firstPart}${year}`;
};
