import classNames from 'classnames';
import React, { type FC } from 'react';
import { type DebugChatBlockContent, type Direction } from '../../types';
import { ChatBubble } from '../ChatBubble';

interface DebugMessageProps extends DebugChatBlockContent {
  direction: Direction;
}

export const DebugMessage: FC<DebugMessageProps> = ({ direction, rows }) => {
  return (
    <div
      className={classNames('mchat-message', 'mchat-debug-message', {
        'mchat-message--in': direction === 'in',
        'mchat-message--out': direction === 'out',
      })}
    >
      <ChatBubble
        direction={direction}
        content={
          <>
            {rows.map((row, index) => {
              return (
                <section key={`index_${index}`}>
                  <h3 className={'mchat-debug-message__title'}>{row.title}</h3>
                  <ul className={'mchat-debug-message__items'}>
                    {row.items.map((item, index2) => {
                      return <li key={`index_${index2}`}>{item}</li>;
                    })}
                  </ul>
                </section>
              );
            })}
          </>
        }
      />
    </div>
  );
};
