import { type DialogConnector } from './DialogConnector';
import MChannelsWsDialogConnector from './MChannelsWsDialogConnector';
import MChannelsRestDialogConnector from './MChannelsRestDialogConnector';

interface getDialogConnectorProps {
  integrationKey?: string;
  baseUrl: string;
  mchannelsBotId: string;
  accountId?: string;
  botId?: string;
  botVersionId?: string;
}

export const getDialogConnector = ({
  integrationKey,
  baseUrl,
  mchannelsBotId,
  accountId,
  botId,
  botVersionId,
}: getDialogConnectorProps): DialogConnector | undefined => {
  if (baseUrl.startsWith('wss://') || baseUrl.startsWith('ws://')) {
    return new MChannelsWsDialogConnector({
      integrationKey,
      baseURL: baseUrl,
      mchannelsBotId,
      accountId,
      botId,
      botVersionId,
    });
  } else if (baseUrl.startsWith('https://') || baseUrl.startsWith('http://')) {
    return new MChannelsRestDialogConnector({
      integrationKey,
      baseURL: baseUrl,
      mchannelsBotId,
    });
  } else {
    console.warn(`No Dialog connector found for  ${baseUrl}`);
  }
};
