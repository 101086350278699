import { useCallback, type SyntheticEvent } from 'react';
import { type OnClickAction, type MChatEvents } from '../types/index';
import useStore from '../state';

const MOBILE_BREAKPOINT = '700px';

function useOnLinkClick() {
  const config = useStore((state) => state.config);
  const closeWidget = useStore((state) => state.actions.closeWidget);
  const setLinkClicked = useStore((state) => state.actions.setLinkClicked);

  const closeWidgetAndKeepSessionOnMobile = useCallback(() => {
    if (window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT})`)?.matches) {
      closeWidget({ keepSession: true });
    }
  }, [closeWidget, window]);

  const sendAnalyticMessage = useStore(
    (state) => state.actions.sendAnalyticMessage,
  );

  const onLinkClick = (
    url: string,
    event: SyntheticEvent, 
    onEvent: (event: MChatEvents) => void,
    onClickAction?: OnClickAction,
    role: 'button' | 'link' = 'link', 
  ) => {
    const action = onClickAction ?? config.defaultOnClickAction;

    const target = event.target as HTMLAnchorElement

    sendAnalyticMessage({
      type: 'widget_interaction',
      subtype: 'click',
      data: {
        source: 'link',
        url: url,
        role: role,
        label: target.innerHTML,
      }
    });

    setLinkClicked(true);

    switch (action) {
      case 'emit_event':
        event.preventDefault();
        closeWidgetAndKeepSessionOnMobile();

        onEvent?.({
          name: 'redirect',
          link: url,
        });
        break;
      case 'open_in_new_tab':
        event.preventDefault();
        closeWidgetAndKeepSessionOnMobile();
        window.open(url, '_blank', 'noopener,noreferrer');
        break;
    }
  };

  return onLinkClick;
}

export default useOnLinkClick;
