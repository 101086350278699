import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';

import {
  UserPromptClosureCountdownPanel,
  UserPromptConfirmationPanel,
} from './UserPromptPanel';

import { ChatBody } from './ChatBody';
import { ChatHeader } from './ChatHeader';
import { ChatOpener } from './ChatOpener';
import { ChatInput } from './ChatInput';
import useStore from '../state';

import { getDialogConnector } from '../dialog-connectors/getDialogConnector';
import type {
  VoiceConfig,
  MChatEvents,
  MChatConfig,
  MChatTranslations,
  ChatInputActions,
  MChatActions,
  SessionValues,
  MChatComponentsCustomization,
  Avatar,
  TransferIntegration,
} from '../types';
import classNames from 'classnames';
import { AudioController } from './AudioController';
import i18n from '../i18n';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { Button } from './common/Buttons/index';

dayjs.extend(utc);

export const MOBILE_BREAKPOINT = '700px';

interface ChatProps {
  integrationKey?: string;
  engine: string;
  baseUrl: string;
  accountId?: string;
  botId?: string;
  botVersionId?: string;
  customTranslations?: MChatTranslations;
  componentsCustomization?: MChatComponentsCustomization;
  sessionValues?: SessionValues;
  transferIntegration?: TransferIntegration;
  config?: MChatConfig;
  voiceConfig?: VoiceConfig;
  avatars?: Avatar[];
  onEvent?: (name: string, data: object) => void;
}

const MCHAT_AUDIO_TERMINATED_IMMEDIATELY: boolean =
  process.env.MCHAT_AUDIO_TERMINATED_IMMEDIATELY === 'true';
// const audioEnabled: boolean = process.env.MCHAT_AUDIO_ENABLED === 'true';

// eslint-disable-next-line react/display-name
export const Chat = forwardRef<MChatActions, ChatProps>(
  (
    {
      integrationKey,
      engine,
      baseUrl,
      accountId,
      botId,
      botVersionId,
      customTranslations,
      componentsCustomization,
      sessionValues,
      transferIntegration,
      config,
      voiceConfig,
      avatars,
      onEvent = () => {},
    },
    ref,
  ) => {
    const isOpen = useStore((state) => state.widgetOpen);
    const openWidget = useStore((state) => state.actions.openWidget);
    const closeWidget = useStore((state) => state.actions.closeWidget);
    const stateSessionId = useStore((state) => state.sessionId);

    const [pushToActivateDisabled, disablePushToActivate] = useState(false);
    const [isScrollingDown, setisScrollingDown] = useState(false);

    const activatedUserPromptPanel = useStore(
      (state) => state.activatedUserPromptPanel,
    );
    // const widgetConfig = useStore((state) => state.config);
    const chatBlocks = useStore((state) => state.chatBlocks);
    const protocolManager = useStore((state) => state.protocolManager);
    const linkClicked = useStore((state) => state.linkClicked);

    const audioManager = useStore((state) => state.audioManager);
    const sendMessage = useStore((state) => state.actions.sendMessage);
    const updateConfig = useStore((state) => state.actions.updateConfig);

    const setAvatars = useStore((state) => state.actions.setAvatars);
    const setSessionValues = useStore(
      (state) => state.actions.setSessionValues,
    );
    const setTransferIntegration = useStore(
      (state) => state.actions.setTransferIntegration,
    );
    const setComponentsCustomization = useStore(
      (state) => state.actions.setComponentsCustomization,
    );

    const deactivateUserPromptPanel = useStore(
      (state) => state.actions.deactivateUserPromptPanel,
    );

    const closeUserPromptPanel = useStore(
      (state) => state.actions.closeUserPromptPanel,
    );

    const connect = useStore((state) => state.actions.connect);
    const maxUserInputLength = useStore(
      (state) => state.currentConfig.maxUserInputLength,
    );

    const mode = useStore(
      (state) => state.currentConfig.mode,
    );

    const chatInputRef = useRef<ChatInputActions>(null);

    const handleOpen = useCallback(() => {
      openWidget();
      setisScrollingDown(false);
      setTimeout(() => {
        chatInputRef.current?.focus();
      }, 100)

    }, [openWidget, setisScrollingDown, chatInputRef.current]);

    useImperativeHandle(
      ref,
      () => {
        return {
          open() {
            handleOpen();
          },
          setUserInput(text: string) {
            chatInputRef.current?.setUserInput(text);
          },
          focusInput() {
            chatInputRef.current?.focus();
          },
        };
      },
      [],
    );

    useEffect(() => {
      setAvatars(avatars);
    }, [avatars]);

    useEffect(() => {
      const threshold = 0;
      let lastScrollY = window.scrollY;
      let ticking = false;

      const updateScrollDir = () => {
        const scrollY = window.scrollY;

        if (Math.abs(scrollY - lastScrollY) < threshold) {
          ticking = false;
          return;
        }

        const value = scrollY > lastScrollY;

        setisScrollingDown(value);
        lastScrollY = scrollY > 0 ? scrollY : 0;
        ticking = false;
      };

      const onScroll = () => {
        if (!ticking) {
          window.requestAnimationFrame(updateScrollDir);
          ticking = true;
        }
      };

      window.addEventListener('scroll', onScroll);

      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }, [setisScrollingDown]);

    useEffect(() => {
      setSessionValues(sessionValues ?? {});
      setTransferIntegration(transferIntegration);
      setComponentsCustomization(componentsCustomization);
      if (customTranslations) {
        for (const [key, value] of Object.entries(customTranslations)) {
          i18n.addResourceBundle(key, 'translation', value, true, true);
        }
      }

      const handleBeforeUnload = (event: Event) => {
        if (window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT})`)?.matches) {
          closeWidget({ keepSession: true });
        }
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, []);

    useEffect(() => {
      updateConfig(config ?? {}, () =>
        getDialogConnector({
          integrationKey,
          baseUrl,
          mchannelsBotId: engine,
          accountId,
          botId,
          botVersionId,
        }),
      );
    }, [baseUrl, engine, accountId, botId, botVersionId, config]);

    useEffect(() => {
      if (isOpen && chatBlocks?.length > 0) {
        void connect(true);
      } else if (isOpen) {
        void connect(false);
      }
    }, []);

    useEffect(() => {
      if(document.activeElement != document.body) {
        return
      }
      setTimeout(() => {
        if (activatedUserPromptPanel && activatedUserPromptPanel.open) {
          return
        }
        chatInputRef.current?.focus();
      }, 100);

    }, [mode])

    useEffect(() => {
      if (pushToActivateDisabled) {
        disablePushToActivate(false);

        if (MCHAT_AUDIO_TERMINATED_IMMEDIATELY) {
          audioManager.currentSession.terminate();
        }
      }
    }, [chatBlocks, pushToActivateDisabled]);

    let lastMessage = useRef<string>('');

    const sendMessageAfterConfirmation = useCallback(() => {
      deactivateUserPromptPanel();
      chatInputRef.current?.setUserInput('');
      chatInputRef.current?.focus()
      sendMessage(lastMessage.current);
    }, [deactivateUserPromptPanel, sendMessage, chatInputRef]);

    const closePanel = useCallback(() => {
      closeUserPromptPanel()
      chatInputRef.current?.focus()
    }, [closeUserPromptPanel, chatInputRef]);

    const sendTextMessage = useCallback(
      (message: string) => {
        sendMessage(message);
        lastMessage.current = message;
        setTimeout(() => {
          if (activatedUserPromptPanel && activatedUserPromptPanel.open) {
            return
          }
          chatInputRef.current?.focus();
        }, 20);
      },
      [sendMessage, chatInputRef.current],
    );

    const handleClose = useCallback(() => {
      closeWidget();
    }, [closeWidget]);

    const onMChatEvent = useCallback(
      (event: MChatEvents) => {
        const { name, ...rest } = event;
        onEvent(event.name, { ...rest });
      },
      [onEvent],
    );

    const onUserPromptPanelConfirmation = useCallback(
      () => {
        chatInputRef.current?.focus()
      },
      [onEvent],
    );

    const renderUserPromptPanel = useCallback(() => {
      switch (activatedUserPromptPanel?.type) {
        case 'UserPromptClosureCountdownPanel':
          return <UserPromptClosureCountdownPanel onConfirmation={onUserPromptPanelConfirmation} />;
        case 'UserPromptMessageConfirmationPanel':
          return (
            <UserPromptConfirmationPanel
              onConfirmation={sendMessageAfterConfirmation}
              onRejection={closePanel}
              {...activatedUserPromptPanel.panelProps}
            />
          );
      }
    }, [activatedUserPromptPanel, sendMessageAfterConfirmation, closePanel, activatedUserPromptPanel?.open]);

    const targetEnvironment = baseUrl.endsWith('/dev') ? 'dev' : 'prod';

    return (
      <div
        className={classNames('mchat-container', {
          'mchat-container--closed': !isOpen,
          'mchat-container--hidden': isScrollingDown && !isOpen,
        })}
      >
        {!isOpen ? (
          <ChatOpener onClick={handleOpen}>Open</ChatOpener>
        ) : (
          <div className='mchat'>
                {!!activatedUserPromptPanel?.open && 
                renderUserPromptPanel()} 
            <ChatHeader onClose={handleClose} />           
              <ChatBody onEvent={onMChatEvent} chatBlocks={chatBlocks} />
              <AudioController
                sessionId={
                  protocolManager?.sessionId ?? stateSessionId ?? undefined
                }
                targetEnvironment={targetEnvironment}
                extension={voiceConfig?.extension ?? ''}
              >
                {(audioState, onAudioButtonClick) => {
                  return (
                    <ChatInput
                      ref={chatInputRef}
                      audioState={voiceConfig ? audioState : undefined}
                      onAudioButtonClick={onAudioButtonClick}
                      onMessage={sendTextMessage}
                      maxCharacters={maxUserInputLength}
                    />
                  );
                }}
              </AudioController>
          </div>
        )}
      </div>
    );
  },
);
