import type { ChatBlock, MChatInstruction, TextChatBlock } from '../types';
import { type TextPreprocessor } from '../utils/TextPreprocessor';

class ProtocolMchatAdapter {
  protected _textPreprocessor?: TextPreprocessor;

  constructor(textPreprocessor?: TextPreprocessor) {
    if (this.constructor === ProtocolMchatAdapter) {
      throw new Error(' Object of Abstract Class cannot be created');
    }

    this._textPreprocessor = textPreprocessor;
  }

  processResponse(response: object): [ChatBlock[], MChatInstruction[]] {
    const [chatBlocks, mchatInstructions] = this._processResponse(response);

    if (!this._textPreprocessor) {
      return [chatBlocks, mchatInstructions];
    }

    const newMChatBlocks = chatBlocks.map((chatBlock) => {
      if (chatBlock.type === 'message') {
        const messageChatBlock: TextChatBlock = chatBlock;

        const newChatBlock = {
          ...messageChatBlock,
        };

        newChatBlock.content.text =
          this._textPreprocessor?.preprocess(newChatBlock.content.text) ??
          newChatBlock.content.text;
        return newChatBlock;
      } else {
        return chatBlock;
      }
    });

    return [newMChatBlocks, mchatInstructions];
  }

  _processResponse(response: object): [ChatBlock[], MChatInstruction[]] {
    throw new Error('Abstract Method has no implementation');
  }
}

export default ProtocolMchatAdapter;
