import classNames from 'classnames';
import {
  type FC,
  type SyntheticEvent,
  useCallback,
  type ReactElement,
} from 'react';
import useStore from '../state';
// import robotAvatar from '../assets/avatars/robot.png';
import { Link } from './common/Link';
import type { Avatar, BubbleHeader, MChatEvents } from '../types';

import useOnLinkClick from '../hooks/useOnLinkClick';
import RowTemplateRenderer from './RowTemplateRenderer';

export const ChatBubble: FC<{
  title?: string;
  content: ReactElement | string;
  link?: string;
  direction?: 'out' | 'in';
  avatar?: Avatar;
  timestamp?: string;
  hideHeader?: boolean;
  onEvent?: (event: MChatEvents) => void;
  onLinkClick?: (event: SyntheticEvent) => void;
}> = ({
  title,
  content,
  link,
  onEvent = () => {},
  direction = 'out',
  avatar,
  hideHeader = false,
  timestamp,
  onLinkClick = () => {},
}) => {
  const onLinkClickHandler = useOnLinkClick();
  const inBubbleHeaderConfig = useStore(
    (state) => state?.componentsCustomization?.InBubbleHeader,
  );
  const outBubbleHeaderConfig = useStore(
    (state) => state?.componentsCustomization?.OutBubbleHeader,
  );

  const handleInnerLinkClick = useCallback(
    (event: SyntheticEvent) => {
      if (event.target instanceof HTMLAnchorElement) {
        if (event.target.nodeName === 'A') {
          onLinkClickHandler(
            event.target.getAttribute('href') ?? '',
            event,
            onEvent,
          );
        }
      }
    },
    [onEvent],
  );

  const renderContent = useCallback(() => {
    return (
      <>
        {typeof content === 'string' ? (
          // eslint-disable-next-line
          <div
            dangerouslySetInnerHTML={createMarkup(content)}
            onClick={handleInnerLinkClick}
          ></div>
        ) : (
          <>{content}</>
        )}
      </>
    );
  }, [handleInnerLinkClick, content]);

  const renderHeader = useCallback(
    (bubbleHeader?: BubbleHeader) => {
      return (
        !hideHeader &&
        bubbleHeader &&
        bubbleHeader.position === 'inside' && (
          <RowTemplateRenderer
            className='mchat-bubble__header'
            rowTemplate={bubbleHeader.content}
            values={values}
          />
        )
      );
    },
    [hideHeader],
  );

  let values = {};
  if (timestamp) {
    values = {
      timestamp,
    };
  }

  const avatarStyle = {
    alignSelf: avatar?.position,
  };

  const imgStyle = {
    width: avatar?.width,
  };

  return (
    <div
      className={classNames('mchat-bubble', {
        'mchat-bubble--in': direction === 'in',
        'mchat-bubble--out': direction === 'out',
      })}
    >
      {avatar && avatar.scope === 'bot_message' && direction === 'in' && (
        <div className='mchat-bubble__avatar' style={avatarStyle}>
          <img
            src={avatar.source}
            style={imgStyle}
            alt={`Avatar - ${avatar.type}`}
          />
        </div>
      )}

      <div className='mchat-bubble__content'>
        {direction === 'in' && renderHeader(inBubbleHeaderConfig)}
        {direction === 'out' && renderHeader(outBubbleHeaderConfig)}

        {title && <div className='mchat-bubble__title'>{title}</div>}
        {direction === 'out' ? <div>{content}</div> : renderContent()}
        {link && (
          <Link href={link} onClick={onLinkClick}>
            Více zde
          </Link>
        )}
      </div>

      {avatar && avatar.scope === 'user_message' && direction === 'out' && (
        <div className='mchat-bubble__avatar' style={avatarStyle}>
          <img
            src={avatar.source}
            style={imgStyle}
            alt={`Avatar - ${avatar.type}`}
          />
        </div>
      )}
    </div>
  );
};

const createMarkup = (htmlContent: string) => {
  return { __html: htmlContent };
};
