import classNames from 'classnames';
import { useCallback, type FC, type SyntheticEvent } from 'react';
import {
  type MChatEvents,
  type Direction,
  type SearchChatBlockContent,
} from '../../types';

import useOnLinkClick from '../../hooks/useOnLinkClick';

import { ChatBubble } from '../ChatBubble';

interface SearchMessageProps extends SearchChatBlockContent {
  direction: Direction;
  onEvent?: (event: MChatEvents) => void;
}

const SearchMessage: FC<SearchMessageProps> = ({
  direction,
  results,
  onEvent = () => {},
}) => {
  const onLinkClickHandler = useOnLinkClick();

  const onLinkClick = useCallback(
    (index: number, link: string, event: SyntheticEvent) => {
      const onClickAction = results[index].onClickAction;
      onLinkClickHandler(link, event, onEvent, onClickAction);
    },
    [onEvent, onLinkClickHandler],
  );

  return (
    <div
      className={classNames('mchat-message', 'mchat-search-message', {
        'mchat-message--in': direction === 'in',
        'mchat-message--out': direction === 'out',
      })}
    >
      {results.map((resultMessage, index) => (
        <ChatBubble
          key={`chat-bubble-${index}`}
          direction={direction}
          onLinkClick={(event) => {
            onLinkClick(index, resultMessage.link ?? '', event);
          }}
          {...resultMessage}
        />
      ))}
    </div>
  );
};

export { SearchMessage };
