import { DialogConnector } from './DialogConnector';

const PROD_SUBDOMAINS = ['chat', 'developer', 'tapir', 'tapir-dev'];

class MChannelsWsDialogConnector extends DialogConnector {
  baseURL: string;
  mchannelsBotId: string;
  accountId?: string;
  botId?: string;
  botVersionId?: string;

  private _socket: WebSocket;

  constructor(params: {
    integrationKey?: string;
    baseURL: string;
    mchannelsBotId: string;
    accountId?: string;
    botId?: string;
    botVersionId?: string;
  }) {
    const {
      integrationKey,
      baseURL,
      mchannelsBotId,
      accountId,
      botId,
      botVersionId,
    } = params;

    super(integrationKey);
    this.baseURL = baseURL;
    this.accountId = accountId;
    this.botId = botId;
    this.botVersionId = botVersionId;
    this.mchannelsBotId = mchannelsBotId;
    this._socket = undefined as unknown as WebSocket;
  }

  async connect(sessionId?: string) {
    this._socket = new WebSocket(this._getEndpoint());
    this._initSocketListeners(sessionId);

    const promise = new Promise<string>((resolve, reject) => {
      this._socket.addEventListener('message', (event: MessageEvent) => {
        try {
          const response = JSON.parse(event.data);
          for (const message of response) {
            if (
              message?.event === 'session_created' ||
              message?.event === 'session_used'
            ) {
              resolve('resolved');
            }
          }
        } catch (e) {}
      });

      this._socket.addEventListener('close', (event: CloseEvent) => {
        const message = `Websocket connection to ${this.baseURL} has been closed with code ${event.code} `;
        console.debug(message, event);
        reject(new Error(message));
      });

      this._socket.addEventListener('error', (event: Event) => {
        const message = `Websocket connection to ${this.baseURL} failed. `;
        console.debug(message, event);
        reject(new Error(message));
      });

      setTimeout(() => {
        reject(
          new Error(`Websocket connection to ${this.baseURL} timeouted. `),
        );
      }, 3000);
    });

    return await promise;
  }

  close() {
    if (this._socket) {
      this._socket.close();
    }
  }

  async send(payload: object) {
    this._send_via_socket([
      {
        event: 'chat_message',
        data: {
          message: payload,
        },
      },
    ]);
    return
  }

  refreshSession(): void {
    this._send_via_socket([
      {
        event: 'refresh_session',
      },
    ]);
  }

  _send_via_socket(payload: object) {
    this._socket.send(JSON.stringify(payload));
  }

  _isAuthorizationRequired() {
    for (const prodSubdomain of PROD_SUBDOMAINS) {
      if (this.baseURL.startsWith('wss://' + prodSubdomain)) {
        return true;
      }
    }
    return false;
  }

  _getEndpoint() {
    if (this.accountId && this.botId) {
      const URL = `${this.baseURL}/ws/engine/${this.mchannelsBotId}/account/${this.accountId}/bot/${this.botId}`;
      if (this.botVersionId) {
        return `${URL}?bot_version=${this.botVersionId}`;
      } else {
        return URL;
      }
    } else {
      return `${this.baseURL}/ws/engine/${this.mchannelsBotId}`;
    }
  }

  _asembleAuthorizationHeader(): string {
    return 'Basic ' + btoa(`${this.mchannelsBotId}:${this._integrationKey}`);
  }

  _initSocketListeners(sessionId?: string) {
    this._socket.addEventListener('open', (event: Event) => {
      if (this._isAuthorizationRequired()) {
        this._send_via_socket({
          authorization: this._asembleAuthorizationHeader(),
        });
      }

      if (sessionId) {
        this._send_via_socket([
          {
            event: 'use_session',
            data: {
              session_id: sessionId,
            },
          },
        ]);
      } else {
        this._send_via_socket([
          {
            event: 'new_session',
          },
        ]);
      }
    });

    this._socket.addEventListener('message', (event: MessageEvent) => {
      try {
        const response = JSON.parse(event.data);
        for (const message of response) {
          if (message?.event === 'chat_response') {
            this.emit('response', message.data);
          }
        }
      } catch (e) {}
    });

    this._socket.addEventListener('close', (event: CloseEvent) => {
      this.emit('close', event);
    });

    this._socket.addEventListener('error', (event: Event) => {
      this.emit('error', event);
    });
  }
}

export default MChannelsWsDialogConnector;
