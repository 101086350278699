import classNames from 'classnames';
import type { FC } from 'react';
import type {
  MChatEvents,
  Direction,
  Avatar,
  ChatBlockMetadata,
} from '../../types';

import { ChatBubble } from '../ChatBubble';

interface TextMessageProps {
  direction: Direction;
  title?: string;
  content: string;
  link?: string;
  avatar?: Avatar;
  timestamp?: string;
  metadata?: ChatBlockMetadata;
  onEvent?: (event: MChatEvents) => void;
}

export const TextMessage: FC<TextMessageProps> = ({
  direction,
  metadata,
  onEvent,
  ...rest
}) => {
  const specialClassName = metadata?.className;

  const specialClassNames = specialClassName
    ? {
        [specialClassName]: true,
      }
    : {};

  return (
    <div
      className={classNames('mchat-message', 'mchat-text-message', {
        'mchat-message--in': direction === 'in',
        'mchat-message--out': direction === 'out',
        ...specialClassNames,
      })}
    >
      <ChatBubble direction={direction} onEvent={onEvent} {...rest} />
    </div>
  );
};
