import { sendAnaliticsMessageCallbackType, type ActiveState, type HumanAgentsState } from '../../TransferBase';
import urljoin from 'url-join';
import isEqual from 'lodash.isequal';
import { TransferController, TransferSession } from '../../TransferBase';
import jsonp from 'jsonp-retry';

interface TransferData {
  title?: string;
  email?: string;
  phone?: string;
  messages?: Array<{
    text: string;
    type: string;
    direction: string;
  }>;
}

interface SessionData {
  chatName: string;
}

interface SessionConfig {
  pollingFrequency?: number;
}

interface PollingResponse {
  result: {
    answered: boolean;
    userIsTyping: boolean;
    disconnection: string;
    users: Array<{
      title: string;
      icon: boolean;
    }>;
    messages: Array<{
      name: string;
      type: string;
      user?: string;
      direction: string;
      time_created: string;
      text: string;
    }>;
  };
}

interface SettingsResponse {
  error: any[];
  result: {
    state: string;
    inputs: object[];
    clientSettings: object;
  };
}

const DEFAULT_API_BASE = 'external/web/api';

export class DaktelaTransferController extends TransferController {
  private readonly _baseUrl: string;
  private readonly _apiBase: string;
  private readonly _accessToken: string;
  private _humanAgentsAvailable:boolean;

  constructor(
    acecessToken: string,
    sendAnaliticsMessageCallback: sendAnaliticsMessageCallbackType,
    config: {
      baseUrl?: string;
      apiBase?: string;
    } = {},
  ) {
    super(sendAnaliticsMessageCallback);

    this._accessToken = acecessToken;
    this._baseUrl = config.baseUrl ?? ''
    this._apiBase = config.apiBase ?? DEFAULT_API_BASE;

    this._humanAgentsAvailable = false;
  }

  async _transfer(data: TransferData): Promise<TransferSession> {
      try {
      
        const response = await jsonp(this._assembleUrlForNewConversation(data), {
          timeout: 3000,
          retryTimes: 3
        });

        const { result } = response;
        if (!result) {
          return Promise.reject(new Error('No result received.'))
        }

        const name = result?.name;
        const error = result?.error;

        if (error?.length > 0) {
          return Promise.reject(new Error(error.toString()))
        }

        if (!name) {
          return Promise.reject(new Error('No chatName received'))
        }

        this.transferSession = new DaktelaTransferSession(
          this._baseUrl,
          this._apiBase,
          this._accessToken,
          this.sendAnaliticsMessageCallback,
          {
            chatName: name,
          },
          {
            pollingFrequency: this._humanAgentsAvailable ? 5000 : undefined,
          },
        );

        const messages = data.messages ?? [];

        if(!this._humanAgentsAvailable) {
          this.sendAnaliticsMessageCallback({
            type: 'widget_transfer',
            subtype: 'outcoming_message',
            data: {
              transfer_id: name,
              message: messages?.length > 0 ? messages[messages.length - 1] : undefined
            }
          })
          await this.transferSession.close()
          return Promise.resolve(this.transferSession)
        }
        
        return Promise.resolve(this.transferSession)
      }
        catch(error){
          return Promise.reject(error);
        }
  }

  async _verifyHumanAgentsAvailability(): Promise<HumanAgentsState> {
    const promise = new Promise<HumanAgentsState>((resolve, reject) => {
      jsonp(this._assembleUrlForSettings(), {
        timeout: 3000,
        retryTimes: 3
      })
        .then((data: SettingsResponse) => {
          if (data.error.length > 0) {
            this._humanAgentsAvailable = false
            resolve({
              available: this._humanAgentsAvailable,
            });
          }
          this._humanAgentsAvailable = data.result.state === 'ONLINE'
          resolve({
            available: this._humanAgentsAvailable,
          });
        })
        .catch((error: any) => {
          reject(error);
        });
    });
    return await promise;
  }

  _assembleUrlForSettings() {
    return urljoin(
      this._baseUrl,
      this._apiBase,
      'settings.jsonp',
      `?accessToken=${this._accessToken}`,
    );
  }

  _assembleUrlForNewConversation(data: TransferData) {
    const inputs = Object.entries(data).map((item) => {
      if (!item[1]) {
        return undefined;
      }

      if (item[0] === 'messages') {
        let messages = '';
        for (let i = 0; i < item[1].length; i++) {
          const value: TransferData['messages'] = item[1];
          const type = 'PUBLIC';
          const direction = 'IN';

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const text = `${value![i].direction === 'in' ? 'B' : 'H'}: ${
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            value![i].text
          }`;
          messages += `&messages[${i}][type]=${type}&messages[${i}][direction]=${direction}&messages[${i}][text]=${encodeURIComponent(
            text,
          )}`;
        }
        return messages;
      } else {
        const value: string = item[1];
        return `&inputs[${item[0]}]=${value}`;
      }
    });

    const filteredInputs: string[] = inputs.filter(
      (item?: string): item is string => typeof item !== 'undefined',
    );

    return urljoin(
      this._baseUrl,
      this._apiBase,
      'newWebchat.jsonp',
      `?accessToken=${this._accessToken}`,
      ...filteredInputs,
    );
  }
}

export class DaktelaTransferSession extends TransferSession {
  private readonly _baseUrl: string;
  private readonly _apiBase: string;
  private readonly _accessToken: string;
  private readonly _sessionData: SessionData;
  private readonly _sessionConfig: SessionConfig;
  private _lastActiveState?: ActiveState;
  private _intervalId?: ReturnType<typeof setInterval>;

  constructor(
    _baseUrl: string,
    _apiBase: string,
    accessToken: string,
    sendAnaliticsMessageCallback: sendAnaliticsMessageCallbackType,
    sessionData: SessionData,
    sessionConfig: SessionConfig,
  ) {
    super(sendAnaliticsMessageCallback);

    this._accessToken = accessToken;
    this._baseUrl = _baseUrl;
    this._apiBase = _apiBase;
    this._sessionData = sessionData;
    this._sessionConfig = sessionConfig;
    this.id = sessionData.chatName

    this.sessionState = {
      state: 'intiated',
    };

    if (this._sessionConfig.pollingFrequency){
      this._setPolling();
    }
  }

  async _send(message: string): Promise<boolean> {
    const chatMessageBaseUrl = this._assembleBaseUrlForAction('sendMessage');
    const promise = new Promise<boolean>((resolve, reject) => {
      jsonp(
        chatMessageBaseUrl +
          '&' +
          new URLSearchParams({
            text: message,
            type: 'PUBLIC',
            direction: 'IN',
          }).toString(),
          {
            timeout: 3000,
            retryTimes: 3
          }
      )
        .then(() => {
          resolve(true);
        })
        .catch(async (error: object) => {
          reject(error);
          this.sessionState = {
            state: 'error',
          };
          this.emit('stateUpdate', this.sessionState);
        });
    });
    return await promise;
  }

  _setPolling() {
    const chatMessageBaseUrl = this._assembleBaseUrlForAction('webPullData');
    let messagesLength = 0;

    this._intervalId = setInterval(() => {
      void (async () => {
        try {
          const jsonResponse: PollingResponse = await jsonp(chatMessageBaseUrl,           {
            timeout: 3000,
            retryTimes: 3
          });

          const stateType = this.sessionState?.state;

          const messages = jsonResponse.result.messages.filter(
            (message) => message.direction !== 'IN',
          );

          if (messages.length > 0) {
            const messagesToAdd = messages
              .slice(messagesLength)
              .map((message) => {

                if (
                  message.type === 'PUBLIC' &&
                  message.text.includes("se připojil/a k chatu")
                ) {
                  message.text = `Operátor ${message.text}`;
                }

                return {
                  text: message.text,
                  user: message.user
                    ? {
                        gender: 'female',
                      }
                    : undefined,
                };
              });
            messagesLength = messages.length;
            this.emit('response', messagesToAdd);
          }

          if (jsonResponse.result.disconnection.length > 0) {
            this.sessionState = {
              state: 'ended',
            };
            this.emit('stateUpdate', this.sessionState);
            clearInterval(this._intervalId);
            return;
          }

          switch (stateType) {
            case 'intiated':
              if (jsonResponse.result.answered) {
                this.sessionState = {
                  state: 'acknowledged',
                };
                this.emit('stateUpdate', this.sessionState);
                this.sessionState = {
                  state: 'active',
                  humanAgentTyping: jsonResponse.result.userIsTyping,
                };
                this.emit('stateUpdate', this.sessionState);
                this._lastActiveState = this.sessionState;
              }
              break;
            case 'active': {
              const newState: ActiveState = {
                state: 'active',
                humanAgentTyping: jsonResponse.result.userIsTyping,
              };
              if (this._hasActiveStateChanged(newState)) {
                this.sessionState = newState;
                this.emit('stateUpdate', this.sessionState);
              }
              this._lastActiveState = newState;
              break;
            }
          }
        } catch (error) {
          this.sessionState = {
            state: 'error',
          };
          this.emit('stateUpdate', this.sessionState);
          console.error(error);
        }
      })();
    }, this._sessionConfig.pollingFrequency);
  }

  async close() {
    if (this.sessionState?.state === 'ended') return;

    this.sessionState = {
      state: 'ended',
    };
    this.emit('stateUpdate', this.sessionState);
    
    if(this._intervalId) {
      clearInterval(this._intervalId);
    }

    const updateWebchatBaseUrl =
      this._assembleBaseUrlForAction('updateWebchat');

    const promise = new Promise<boolean>((resolve, reject) => {
      jsonp(
        updateWebchatBaseUrl +
          '&' +
          new URLSearchParams({
            disconnection: 'CLIENT',
          }).toString(),
          {
            timeout: 3000,
            retryTimes: 3
          }
      )
        .then((response:object) => {
          console.debug(response);
          resolve(true);
        })
        .catch(async (error:object) => {
          console.debug(error);
          reject(error);
        });
    });
    return await promise;
  }

  _createActiveStateBasedOnResponse(response: PollingResponse): ActiveState {
    return {
      state: 'active',
      humanAgentTyping: response.result.userIsTyping,
    };
  }

  _hasActiveStateChanged(state: ActiveState) {
    return !isEqual(this._lastActiveState, state);
  }

  _assembleBaseUrlForAction(
    action: 'sendMessage' | 'webPullData' | 'updateWebchat',
  ) {
    return (
      urljoin(
        this._baseUrl,
        this._apiBase,
        action,
        `${this._sessionData.chatName}.jsonp?`,
      ) +
      new URLSearchParams({
        accessToken: this._accessToken,
      }).toString()
    );
  }
}
