import React, { SyntheticEvent, useCallback, type FC } from 'react';
import type {
  MChatEvents,
  HyperlinksGroupChatBlockContent,
  Direction,
  OnClickAction,
} from '../../types';
import classNames from 'classnames';
import { HyperlinkButton } from '../common/HyperlinkButton';
import useStore from '../../state';
import useOnLinkClick from '../../hooks/useOnLinkClick';

interface HyperlinksGroupMessageProps extends HyperlinksGroupChatBlockContent {
  onEvent: (event: MChatEvents) => void;
  direction: Direction;
}

export const HyperlinksGroupMessage: FC<HyperlinksGroupMessageProps> = ({
  hyperlinks,
  direction,
  onEvent,
}) => {

  const onLinkClickHandler = useOnLinkClick();

  const onLinkClick = useCallback(
    (event: SyntheticEvent, url: string, onClickAction: OnClickAction) => {

      onLinkClickHandler(url, event, onEvent, onClickAction, 'button');
    },
    [onEvent, onLinkClickHandler],
  );

  return (
    <div
      className={classNames('mchat-message', 'mchat-hyperlinks-group-message', {
        'mchat-message--in': direction === 'in',
        'mchat-message--out': direction === 'out',
      })}
    >
      {hyperlinks.map((hyperlink, index) => {
        const { onClickAction, url, text } = hyperlink;
        return (
          <HyperlinkButton
            key={`hyperlink-${index}`}
            url={url}
            onClick={(event) => onLinkClick(event, url, onClickAction)}
          >
            {text}
          </HyperlinkButton>
        );
      })}
    </div>
  );
};
