import EventEmitter from 'eventemitter3';
import { AnalyticMessage } from '../../types';
import { AnalyticMessageOptions } from '../../protocol-managers/ProtocolManager';

export type SessionStateType =
  | 'intiated'
  | 'acknowledged'
  | 'active'
  | 'disconnected'
  | 'ended'
  | 'error';

export interface ActiveState {
  state: 'active';
  humanAgentTyping: boolean;
}

export interface HumanAgentMessage {
  text: string;
  user?: {
    gender: 'male' | 'female';
  };
}

export interface TransferOptions {
  oneTimeMessage: boolean;
}

export type TransferSessionState =
  | {
      state: SessionStateType;
    }
  | ActiveState;

export interface HumanAgentsState {
  available: boolean;
}

interface TransferSessionEvents {
  response: (messages: HumanAgentMessage[]) => void;
  stateUpdate: (state: TransferSessionState) => void;
}

export type sendAnaliticsMessageCallbackType = (param: AnalyticMessage, options?: AnalyticMessageOptions) => void;

export abstract class TransferController {
  public transferSession?: TransferSession;
  sendAnaliticsMessageCallback: sendAnaliticsMessageCallbackType;

  transfer(data: object): Promise<TransferSession> {
    this.sendAnaliticsMessageCallback({
      type: 'widget_transfer',
      subtype: 'initiated',
    })

    try{
      return this._transfer(data);
    }
    catch(error) {
      this.sendAnaliticsMessageCallback({
        type: 'widget_transfer',
        subtype: 'error',
        data: {
          error: error
        }
      })
      return Promise.reject(error)
    }

  }

  abstract _transfer(data: object): Promise<TransferSession>;

  async verifyHumanAgentsAvailability(): Promise<HumanAgentsState> {
    try {
      const human_agents_state = await this._verifyHumanAgentsAvailability()
      this.sendAnaliticsMessageCallback({
        type: 'widget_transfer',
        subtype: 'human_agents_availability_check',
        data: {
          ...human_agents_state
        },
      }, {
        postpone: true
      } )
      return human_agents_state 
    } catch(error) {
      this.sendAnaliticsMessageCallback({
        type: 'widget_transfer',
        subtype: 'error',
        data: {
          error: error
        }
      }, {
      postpone: true
      })
      return Promise.reject(error)
    }

  }

  abstract _verifyHumanAgentsAvailability(): Promise<HumanAgentsState>;

  constructor(sendAnaliticsMessageCallback: sendAnaliticsMessageCallbackType) {
    this.sendAnaliticsMessageCallback = sendAnaliticsMessageCallback;
  }
}

export declare interface TransferSession {
  on: <U extends keyof TransferSessionEvents>(
    event: U,
    listener: TransferSessionEvents[U],
  ) => this;
}

export abstract class TransferSession extends EventEmitter<
  keyof TransferSessionEvents
> {
  public sessionState?: TransferSessionState;
  protected sendAnaliticsMessageCallback: sendAnaliticsMessageCallbackType;

  constructor(sendAnaliticsMessageCallback: sendAnaliticsMessageCallbackType) {
    super();
    this.sendAnaliticsMessageCallback = sendAnaliticsMessageCallback;

    this.on('response', (messages) => {
      if(!messages || messages.length === 0) {
        return
      }

      this.sendAnaliticsMessageCallback({
        type: 'widget_transfer',
        subtype: 'incoming_messages',
        data: {
          messages
        }
      })
    })

    this.on('stateUpdate', (state) => {
      this.sendAnaliticsMessageCallback({
        type: 'widget_transfer',
        subtype: 'state_update',
        data: {
          state: state
        }
      })
    })
  }

  send(message: string): Promise<boolean> {
    this.sendAnaliticsMessageCallback({
      type: 'widget_transfer',
      subtype: 'outcoming_message',
      data: {
        message: message
      }
    })
    return this._send(message);
  }
  abstract _send(message: string): Promise<boolean>;
  abstract close(): void;
}
