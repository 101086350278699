import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

void i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      cs: {
        translation: {
          header__title: 'mChat',
          input__placeholder: 'Můžete psát...',
          connection_error_user_message:
            'Omlouvám se, ale nejsem aktuálně k dispozici. Zkuste to prosím později.',
          response_timeout_error_user_message:
            'Stále pilně dávám dohromady odpověď. Můžete zkusit ještě chvilku počkat a nebo se vraťte později',
        },
      },
      en: {
        translation: {
          header__title: 'mChat',
          input__placeholder: 'Start typing here...',
          connection_error_user_message:
            "I'm sorry, I'm not available at the moment. Please try again later.",
          response_timeout_error_user_message:
            "I'm still piecing together the answer. You can try to wait a little longer or come back later",
        },
      },
    },
  });

export default i18n;
