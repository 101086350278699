export abstract class TextPreprocessor {
  preprocess(text: string): string {
    throw new Error('Abstract method has no implementation');
  }
}

export class HTMLTextPreprocessor extends TextPreprocessor {
  preprocess(text: string): string {
    // replace new line symbols with <br/>
    let preprocessedText = text.replaceAll('\\n', '<br/>');
    preprocessedText = preprocessedText.replaceAll('\n', '<br/>');

    // replace all the spaces with &nbsp if number of spaces is greater than 1
    const splittedText = preprocessedText.split(' ');
    preprocessedText = splittedText
      .map((text) => text.replace(' ', '&nbsp'))
      .reduce(
        (acc: string, text: string) =>
          acc + (text === '' ? '&nbsp' : ' ') + text,
        '',
      );

    return preprocessedText;
  }
}
