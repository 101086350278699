import { UserPromptClosureCountdownPanel } from './../components/UserPromptPanel';
import { StringifyOptions } from 'querystring';
import { getMessageChatBlock } from '../helpers/ChatBlocks';
import { type TextPreprocessor } from '../utils/TextPreprocessor';

import type {
  HyperlinkChatBlock,
  ButtonsChatBlock,
  SearchChatBlock,
  TextChatBlock,
  ImageChatBlock,
  DebugChatBlock,
  MChatInstruction,
  ChatBlock,
  FeedbackChatBlock,
} from './../types/index';
import ProtocolMchatAdapter from './ProtocolMchatAdapter';

interface TextResponseType {
  response_type: 'text';
  text: string;
  metadata?: {
    class_name?: string;
  };
}

type  Action = ConfirmUserInputAction;

interface ConfirmUserInputAction {
  command: 'ConfirmUserInput',
  params: {
    prompt: string
    confirmButtonLabel: string
    rejectButtonLabel: string
    multilineInput?: boolean
}
}

interface TextChunkResponseType {
  response_type: 'text_chunk';
  message_id: string;
  text: string;
}

interface EventResponseType {
  response_type: 'event';
  event: string;
}

interface ImageResposeType {
  response_type: 'image';
  source: string;
}

interface SearchResponseType {
  response_type: 'search';
  results: [SearchResponseTypeItem];
}

interface HyperlinkResponseType {
  response_type: 'hyperlink';
  url: string;
  preference: string;
  text: string;
  on_click: 'emit_event' | 'redirect' | 'open_in_new_tab';
}

interface OptionResponseType {
  response_type: 'option';
  prompt?: string;
  options: OptionResponseTypeItem[];
}

interface FeedbackResponseType {
  response_type: 'feedback';
  kind: 'stars' | 'thumbs' | 'numeric_scale';
  title: string;
  text: string;
  compact: boolean;
  options: {
    min: number;
    max: number;
    step: number;
  };
  feedback_comment: {
    title: string;
    max_length: number;
    requested_up_to: number;
  };
}

type ResponseType =
  | TextResponseType
  | TextChunkResponseType
  | SearchResponseType
  | OptionResponseType
  | HyperlinkResponseType
  | ImageResposeType
  | EventResponseType
  | FeedbackResponseType;

interface SearchResponseTypeItem {
  title: string;
  link: string;
  result: string;
  result_metadata: object;
  on_click: 'emit_event' | 'redirect' | 'open_in_new_tab';
}

interface OptionResponseTypeItem {
  label: string;
  value: {
    input: {
      text: string;
      nlu: object;
    };
  };
}

interface Response {
  output?: {
    generic?: ResponseType[];
    debug?: DebugResponse;
  };
  context?: {
    action?: Action
  }
}

interface DebugResponse {
  nlu?: {
    intents: Array<{
      intent: string;
      confidence: number;
    }>;
    entities: Array<{
      entity: string;
      value: string;
    }>;
  };
  messages?: Array<{
    source: string;
    message: string;
  }>;
}

class MChannelsProtocolMchatAdapter extends ProtocolMchatAdapter {
  private readonly responseActionMapper: {
    text: (response: any) => TextChatBlock[];
    text_chunk: (response: any) => TextChatBlock[];
    option: (response: any) => ButtonsChatBlock[];
    search: (response: any) => SearchChatBlock[];
    hyperlink: (response: any) => HyperlinkChatBlock[];
    image: (response: any) => ImageChatBlock[];
    feedback: (response: any) => FeedbackChatBlock[];
  };

  constructor(textPreprocessor?: TextPreprocessor) {
    super(textPreprocessor);
    this.responseActionMapper = {
      text: this.getMessageBlock,
      text_chunk: this.getStreamedMessageBlock,
      option: this.getButtonsBlock,
      search: this.getSearchBlock,
      hyperlink: this.getHyperlinkBlock,
      image: this.getImageBlock,
      feedback: this.getFeedbackBlock,
    };
  }

  _processResponse(response: Response): [ChatBlock[], MChatInstruction[]] {
    const chatBlocks = [];
    const instructions = [];
    const responseTypes = response?.output?.generic ?? [];
    for (const responseType of responseTypes) {
      if (responseType.response_type === 'event') {
        instructions.push(...this.processEvent(responseType));
      } else if (responseType.response_type in this.responseActionMapper) {
        chatBlocks.push(
          ...this.responseActionMapper[responseType.response_type](
            responseType,
          ),
        );
      }
    }

    if (response?.context?.action) {
      const actionInstructions = this._proccess_action(response?.context?.action)

      if(actionInstructions) {
        instructions.push(...actionInstructions)
      }
    }

    if (response?.output?.debug) {
      chatBlocks.push(...this.createDebugBlock(response?.output?.debug));
    }

    return [chatBlocks, instructions];
  }

  private _proccess_action(action: Action) : MChatInstruction[] {
    switch(action.command){
      case 'ConfirmUserInput':
        return [{
          name: 'confirmUserInput',
          prompt: action?.params?.prompt,
          confirmButtonLabel: action?.params?.confirmButtonLabel,
          rejectButtonLabel: action?.params?.rejectButtonLabel,
          inputType: action?.params?.multilineInput ? 'multi-line' : 'one-line'
        }]
        default: 
        return []
    } 
  }

  private processEvent(response: EventResponseType): MChatInstruction[] {
    switch (response.event) {
      case 'turn_finished':
        return [
          {
            name: 'finishTurn',
          },
        ];
      default:
        return [];
    }
  }

  private getButtonsBlock(response: OptionResponseType): ButtonsChatBlock[] {
    const buttons = response.options.map((option) => ({
      title: option.label || option?.value?.input?.text,
      textualValue: option?.value?.input?.text,
      extraValue: option?.value?.input,
    }));

    return [
      {
        type: 'buttons',
        direction: 'in',
        content: {
          prompt: response?.prompt,
          buttons,
        },
      },
    ];
  }

  private getMessageBlock(response: TextResponseType) {
    if (response?.text?.trim().length === 0) {
      return [];
    }

    const chatBlock = getMessageChatBlock(response.text.trim());

    if (response.metadata?.class_name) {
      chatBlock.metadata = {
        className: response.metadata?.class_name,
      };
    }

    const propsArray: TextChatBlock[] = [chatBlock];

    return propsArray;
  }

  private getStreamedMessageBlock(
    response: TextChunkResponseType,
  ): TextChatBlock[] {
    if (response?.text?.trim().length === 0) {
      return [];
    }

    const propsArray: TextChatBlock[] = [getMessageChatBlock(response.text)];
    propsArray[0].id = response.message_id;
    propsArray[0].streamed = true;

    return propsArray;
  }

  private getSearchBlock(response: SearchResponseType): SearchChatBlock[] {
    const { results } = response;

    if (!results) {
      return [];
    }

    const mChatResults = results.map((resultItem) => {
      const { title, result, link, on_click: onClick } = resultItem;
      return {
        title,
        content: result,
        link,
        onClickAction: onClick,
      };
    });

    return [
      {
        type: 'search',
        direction: 'in',
        content: {
          results: mChatResults,
        },
      },
    ];
  }

  private getHyperlinkBlock(
    response: HyperlinkResponseType,
  ): HyperlinkChatBlock[] {
    const { url, text, preference, on_click: onClick } = response;

    return [
      {
        type: 'hyperlink',
        direction: 'in',
        content: {
          url,
          text,
          preference,
          onClickAction: onClick,
        },
      },
    ];
  }

  private getImageBlock(response: ImageResposeType): ImageChatBlock[] {
    const { source } = response;

    return [
      {
        type: 'image',
        direction: 'in',
        content: {
          source,
        },
      },
    ];
  }

  private getFeedbackBlock(
    response: FeedbackResponseType,
  ): FeedbackChatBlock[] {
    const {
      kind,
      title,
      text,
      options,
      // eslint-disable-next-line
      feedback_comment,
      compact,
    } = response;
    const feedbackComment = feedback_comment
      ? {
          title: feedback_comment.title,
          maxLength: feedback_comment.max_length,
          requestedUpTo: feedback_comment.requested_up_to,
        }
      : undefined;

    return [
      {
        type: 'feedback',
        direction: 'in',
        content: {
          kind,
          title,
          text,
          compact,
          options,
          feedbackComment,
        },
      },
    ];
  }

  private createDebugBlock(debugResponse: DebugResponse) {
    if (!debugResponse) {
      return [];
    }

    const intentsRow = [];

    if (debugResponse?.nlu?.intents && debugResponse?.nlu?.intents.length > 0) {
      const intents = {
        title: 'Intents',
        items: debugResponse.nlu.intents.map((intent) => {
          return `#${intent.intent}:${intent.confidence.toFixed(2)}`;
        }),
      };
      intents.items = intents.items.slice(0, Math.min(intents.items.length, 3));
      intentsRow.push(intents);
    }

    const entitiesRow = [];

    if (
      debugResponse?.nlu?.entities &&
      debugResponse?.nlu?.entities.length > 0
    ) {
      entitiesRow.push({
        title: 'Entities',
        items: debugResponse.nlu.entities.map((entity) => {
          return `#${entity.entity}:${entity.value}`;
        }),
      });
    }

    const debugMessages: Record<string, string[]> = {};

    if (debugResponse?.messages && debugResponse?.messages.length > 0) {
      for (const message of debugResponse.messages) {
        if (!(message.source in debugMessages)) {
          debugMessages[message.source] = [];
        }
        debugMessages[message.source].push(message.message);
      }
    }

    const messageDebugRows = [];

    for (const key in debugMessages) {
      messageDebugRows.push({
        title: key,
        items: debugMessages[key],
      });
    }

    if (
      intentsRow.length === 0 &&
      entitiesRow.length === 0 &&
      messageDebugRows.length === 0
    ) {
      return [];
    }

    const propsArray: DebugChatBlock[] = [
      {
        type: 'debug',
        direction: 'in',
        content: {
          rows: [...intentsRow, ...entitiesRow, ...messageDebugRows],
        },
      },
    ];

    return propsArray;
  }
}

export default MChannelsProtocolMchatAdapter;
