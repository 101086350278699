import React, { type ReactNode, type FC, type SyntheticEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare';

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  href: string;
  onClick?: (event: SyntheticEvent) => void;
}

export const Link: FC<LinkProps> = ({ children, href, onClick = () => {} }) => {
  return (
    <a className='mchat-hyperlink' href={href} onClick={onClick}>
      <span>{children}</span>
      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
    </a>
  );
};
