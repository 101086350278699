import React, { type FC, useMemo, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { type ChatBlock, type MChatEvents } from '../types';
import { ButtonsMessage } from './message-kinds/ButtonsMessage';
import { TextMessage } from './message-kinds/TextMessage';
import { SearchMessage } from './message-kinds/SearchMessage';
import { HyperlinkMessage } from './message-kinds/HyperlinkMessage';
import { HyperlinksGroupMessage } from './message-kinds/HyperlinksGroupMessage';
import { DebugMessage } from './message-kinds/DebugMessage';
import { assertUnreachable } from '../utils/assertUnreachable';
import { ImageMessage } from './message-kinds/ImageMessage';
import { TypingMessage } from './message-kinds/TypingMessage';
import useStore from '../state';
import { FeedbackMessage } from './message-kinds/FeedbackMessage';

const scrollToBottom = (element: Element | undefined) => {
  setTimeout(() => {
    element?.scrollIntoView({ behavior: 'smooth' });
  }, 20);
};

export const ChatBody: FC<{
  chatBlocks: ChatBlock[];
  onEvent: (event: MChatEvents) => void;
}> = ({ chatBlocks, onEvent }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const lastNumberOfItems = useRef<number>(0);
  const typing = useStore((state) => state.typing);
  const transferSessionState = useStore((state) => state.transferSessionState);
  const avatars = useStore((state) => state.avatars);

  useEffect(() => {
    if (lastNumberOfItems.current === 0) {
      lastNumberOfItems.current = chatBlocks.length;
      scrollToBottom(messagesEndRef?.current as Element);
      return;
    }

    const countDiff = chatBlocks.length - lastNumberOfItems.current;
    if (countDiff > 0) {
      scrollToBottom(
        messagesEndRef.current?.parentElement?.children[
          chatBlocks.length - countDiff
        ],
      );
    } else if (typing) {
      scrollToBottom(messagesEndRef?.current as Element);
    }
    lastNumberOfItems.current = chatBlocks.length;
  }, [chatBlocks, typing]);

  let lastIndexOfOutMessage = [...chatBlocks]
    .reverse()
    .findIndex((item: ChatBlock) => item.direction === 'out');

  if (lastIndexOfOutMessage > -1) {
    lastIndexOfOutMessage = chatBlocks.length - 1 - lastIndexOfOutMessage;
  }

  const avatar = useMemo(() => {
    if (transferSessionState?.state === 'active') return;

    return avatars?.find((item) => item.type === 'robot');
  }, [avatars, transferSessionState?.state]);

  const classes = classNames('mchat-body', {
    'mchat-body--avatars': (avatars?.length ?? 0) > 0,
  });

  return (
    <div className={classes}>
      {chatBlocks.map((chatBlock, i) => {
        const { direction, type, content } = chatBlock;
        // const nextMessage = chatBlocks[i + 1];
        switch (type) {
          case 'message':
            return (
              <TextMessage
                key={`messsage-${i}`}
                direction={direction}
                avatar={chatBlock.avatar}
                timestamp={chatBlock.timestamp}
                metadata={chatBlock.metadata}
                content={content.text}
                onEvent={onEvent}
              ></TextMessage>
            );
          case 'buttons':
            return (
              <ButtonsMessage
                key={`messsage-${i}`}
                direction={direction}
                {...content}
              ></ButtonsMessage>
            );
          case 'search':
            return (
              <SearchMessage
                key={`messsage-${i}`}
                direction={direction}
                {...content}
                onEvent={onEvent}
              ></SearchMessage>
            );
          case 'hyperlinks-group':
            return (
              <HyperlinksGroupMessage
                key={`messsage-${i}`}
                direction={direction}
                {...content}
                onEvent={onEvent}
              />
            );
          case 'hyperlink':
            return (
              <HyperlinkMessage
                direction={direction}
                key={`messsage-${i}`}
                {...content}
                onEvent={onEvent}
              />
            );
          case 'image':
            return (
              <ImageMessage
                key={`messsage-${i}`}
                direction={direction}
                source={content.source}
              ></ImageMessage>
            );
          case 'debug':
            return (
              <DebugMessage
                key={`messsage-${i}`}
                direction={direction}
                rows={content.rows}
              ></DebugMessage>
            );
          case 'feedback':
            return (
              <FeedbackMessage
                key={`messsage-${i}`}
                isEnabled={i > lastIndexOfOutMessage}
                {...content}
              ></FeedbackMessage>
            );
          default:
            return assertUnreachable(type);
        }
      })}
      {typing && <TypingMessage avatar={avatar} />}
      <div ref={messagesEndRef} />
    </div>
  );
};
