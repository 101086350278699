import classNames from 'classnames';
import React, { type FC } from 'react';
import { ChatBubble } from '../ChatBubble';
import { type Avatar } from '../../types';

export const TypingMessage: FC<{
  avatar?: Avatar;
}> = ({ avatar }) => {
  return (
    <div
      className={classNames(
        'mchat-message',
        'mchat-typing-message',
        'mchat-message--in',
      )}
    >
      <ChatBubble
        direction='in'
        hideHeader={true}
        avatar={avatar}
        content={
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 30 16'
            className='mchat-typing-message__svg'
          >
            <circle
              className='mchat-typing-message__circle-1'
              cx='8'
              cy='8'
              r='1'
            />
            <circle
              className='mchat-typing-message__circle-2'
              cx='15'
              cy='8'
              r='1'
            />
            <circle
              className='mchat-typing-message__circle-3'
              cx='22'
              cy='8'
              r='1'
            />
          </svg>
        }
      />
    </div>
  );
};
