import JsSIP from 'jssip';
import type { RTCSession } from 'jssip/lib/RTCSession';

interface IcecandidateEvent {
  candidate: {
    candidate: string;
  };
  ready: () => void;
}

const RTC_SESSION_EVENTS = [
  'peerconnection',
  'connecting',
  'sending',
  'progress',
  'accepted',
  'confirmed',
  'ended',
  'failed',
  'newDTMF',
  'newInfo',
  'hold',
  'unhold',
  'muted',
  'unmuted',
  'reinvite',
  'update',
  'refer',
  'replaces',
  'sdp',
  'icecandidate',
  'getusermediafailed',
  'peerconnection:createofferfailed',
  'peerconnection:createanswerfailed',
  'peerconnection:setlocaldescriptionfailed',
  'peerconnection:setremotedescriptionfailed',
];

// function isChromeBrowser(): boolean {
//   // Method 1: Checking User Agent string
//   const isChromeUserAgent =
//     /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
//   const isChromeObject = typeof chrome !== 'undefined';

//   return isChromeUserAgent || isChromeObject;
// }

class AsteriskDialogConnector {
  socket: JsSIP.Socket;
  extension: string;
  ua: JsSIP.UA;
  sessionId: string;
  currentSession?: RTCSession;
  audioElement?: HTMLAudioElement;

  onRTCSessionEvent(eventName: string, event: object) {}

  async connect() {
    const promise = new Promise((resolve, reject) => {
      function onRegistered(e: object) {
        resolve('Client registered');
      }

      this.ua.on('registered', onRegistered);

      this.ua.on('registrationFailed', function (e) {
        reject(e);
      });

      this.ua.start();
    });

    return await promise;
  }

  call(): RTCSession {
    const eventHandlers: Record<string, (x: object) => void> = {};

    for (const rTCSessionEvent of RTC_SESSION_EVENTS) {
      eventHandlers[rTCSessionEvent] = (event: object) => {
        this.onRTCSessionEvent(rTCSessionEvent, event);
      };
    }

    const options = {
      eventHandlers,
      extraHeaders: ['x-mchannels-session-id: ' + this.sessionId],
      mediaConstraints: { audio: true, video: false },
      pcConfig: {
        iceServers: [
          {
            urls: 'stun:stun.relay.metered.ca:80',
          },
          {
            urls: 'turn:a.relay.metered.ca:80',
            username: '1868745cd0e2d10a3d8691b3',
            credential: 'OZTl+zE+Y4RXcsFl',
          },
          // {
          //   urls: 'turn:a.relay.metered.ca:80?transport=tcp',
          //   username: '1868745cd0e2d10a3d8691b3',
          //   credential: 'OZTl+zE+Y4RXcsFl',
          // },
          {
            urls: 'turn:a.relay.metered.ca:443',
            username: '1868745cd0e2d10a3d8691b3',
            credential: 'OZTl+zE+Y4RXcsFl',
          },
          // {
          //   urls: 'turn:a.relay.metered.ca:443?transport=tcp',
          //   username: '1868745cd0e2d10a3d8691b3',
          //   credential: 'OZTl+zE+Y4RXcsFl',
          // },
        ],
      },
    };

    this.currentSession = this.ua.call(this.extension, options);

    // if (isChromeBrowser()) {

    //   this.currentSession.on('icecandidate', function (event) {
    //     if (
    //       event.candidate.type === 'srflx' &&
    //       event.candidate.relatedAddress !== null &&
    //       event.candidate.relatedPort !== null
    //     ) {
    //       setTimeout(() => {
    //         event.ready();
    //       }, 20);
    //     }
    //   });
    // }

    let myCandidateTimeout: ReturnType<typeof setTimeout>;

    // tackle 40 sec delay after intitiating a call
    this.currentSession.on('icecandidate', function (event: IcecandidateEvent) {
      const { ready } = event;

      // clearTimeout(myCandidateTimeout);
      if (myCandidateTimeout == null) {
        // new approach - wait at most 500ms after first candidate arrives
        myCandidateTimeout = setTimeout(ready, 500);
      }
    });

    this.currentSession.connection.addEventListener('track', (e: any) => {
      void (async () => {
        // set remote audio stream
        this.audioElement = document.createElement('audio');
        this.audioElement.srcObject = e.streams[0];
        await this.audioElement.play();
      })();
    });

    return this.currentSession;
  }

  /**
   * @param sipUri SIP URI associated to the User Agent (String). This is a SIP address given to you by your provider.
   * @param sipUser Username (String) to use when generating authentication credentials.
   * @param sipPassword SIP Authentication password
   * @param socketUrl WebSocket server URL
   * @param sessionId The session id to whitch to connect
   * @param extension Asterisk extension (e.g. 'animals-cs-dev')
   */
  constructor(
    sipUri: string,
    sipUser: string,
    sipPassword: string,
    socketUrl: string,
    sessionId: string,
    extension: string,
  ) {
    this.socket = new JsSIP.WebSocketInterface(socketUrl);
    this.sessionId = sessionId;
    this.extension = extension;

    const configuration = {
      sockets: [this.socket],
      uri: sipUri,
      authorization_user: sipUser,
      password: sipPassword,
    };

    this.ua = new JsSIP.UA(configuration);
    this.ua
      .registrator()
      .setExtraHeaders(['x-mchannels-session-id:' + this.sessionId]);

    /*
      this._socket.onmessage = (event) => {
            let that = this
            console.log('message', event.data)
            try {
                const data = JSON.parse(event.data)
                that.context = event.data.context
                console.log('data', data)
                return that.onResponse(data)
            }
            catch (e) {

            }
        }
        this.ua.onconnecte =
        */
  }
}

export default AsteriskDialogConnector;

/*
import urljoin from 'url-join';
import axios from 'axios';
import DialogConnector from './DialogConnector';
 
class TeresaDialogConnector extends DialogConnector {
    baseURL:string
    botId: string
    context: object

    private _axiosInstance:object

    constructor(baseURL:string, botId:string){
        super()
        this.baseURL = baseURL
        this.botId = botId
        this.context = {}

        this._axiosInstance = axios.create({
            baseURL
          });
    }      

    async sendUserInput(input){
        const response = await this._axiosInstance.post(getMessageEndpointPath(this.baseURL, this.botId), {
            input: {
                text: input
            },
            context: this.context,
            output: {}
            },
            {
                headers: {
                    "X-mchat-api-key": "" //TODO
                }
            }
            );

            const {data} = response

            this.context = data.context
    
            return data
        }

    reset() {
        this.context = {}
    }    
}

const getMessageEndpointPath = (apiBase, botId) => {
    return urljoin(apiBase, "bots", botId, "message");
}

export default TeresaDialogConnector


*/
