import {
  type ReactNode,
  type FC,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import useStore from '../state';
import { type UserPromptConfirmationPanelProps } from '../types/index';

export const UserPromptPanel: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className='mchat-user-prompt-panel'>{children}</div>;
};

interface UserPromptClosureCountdownPanelProps {
  onConfirmation?: () => void;
}

export const UserPromptClosureCountdownPanel: FC<
  UserPromptClosureCountdownPanelProps
> = (props) => {
  const { onConfirmation = () => {} } = props

  const buttonRef = useRef<HTMLButtonElement>(null);

  const modalRef = useRef<HTMLDivElement>(null);

  const refreshSession = useStore((state) => state.actions.refreshSession);
  const deactivateUserPromptPanel = useStore(
    (state) => state.actions.deactivateUserPromptPanel,
  );
  const closeWidget = useStore((state) => state.actions.closeWidget);
  const config = useStore((state) => state.config);
  const lastUserActivityTimestamp = useStore(
    (state) => state.lastUserActivityTimestamp,
  );
  const [now, setNow] = useState(Date.now());

  const handleConfirmation = useCallback(() => {
    refreshSession();
    deactivateUserPromptPanel();
    onConfirmation();
  }, [refreshSession]);

  const handleRejection = useCallback(() => {
    closeWidget();
  }, [closeWidget]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const modalElement = modalRef.current;
    //add any focusable HTML element you want to include to this string
    const focusableElements = modalElement?.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );

    if (!focusableElements?.length) {
      return;
    }

    const firstElement = focusableElements[0] as HTMLElement;
    const lastElement = focusableElements[
      focusableElements.length - 1
    ] as HTMLElement;

    const handleTabKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        if (event.shiftKey && document.activeElement === firstElement) {
          event.preventDefault();
          lastElement.focus();
        } else if (!event.shiftKey && document.activeElement === lastElement) {
          event.preventDefault();
          firstElement.focus();
        }
      }
    };

    modalElement?.addEventListener('keydown', handleTabKeyPress);

    return () => {
      modalElement?.removeEventListener('keydown', handleTabKeyPress);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      buttonRef.current?.focus();
    }, 10);
  }, [buttonRef?.current]);

  const remainingTime = useMemo(() => {
    const res =
      config.userInactivityTimeout - (now - lastUserActivityTimestamp) / 1000;
    if (res > 0) {
      return Math.round(res);
    }
    return 0;
  }, [now, lastUserActivityTimestamp]);

  return (
    <div
      ref={modalRef}
      className='mchat-user-prompt-panel'
      tabIndex={-1}
      aria-modal='true'
      role='dialog'
    >
      <div className='mchat-user-prompt-panel__body'>
        <div className='mchat-user-prompt-panel__prompt'>
          <span className='mchat-primary-color mchat-text-with-icon'>
            <div className='mchat-time-left-icon'></div>
            <span>{renderRemainingTime(remainingTime)}</span>
          </span>
          <span>Přejete si pokračovat?</span>
        </div>
        <div className='mchat-user-prompt-panel__actions'>
          <button
            tabIndex={0}
            className='mchat-user-prompt-panel-button mchat-user-prompt-panel-button--secondary'
            onClick={handleRejection}
          >
            <span>Ne, to je vše</span>
          </button>
          <button
            tabIndex={0}
            ref={buttonRef}
            className='mchat-user-prompt-panel-button'
            onClick={handleConfirmation}
          >
            <span>Ano, přeji</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const renderRemainingTime = (remainingTime: number) => {
  const minutes = Math.floor(remainingTime / 60).toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });

  const seconds = (remainingTime % 60).toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });
  return `${minutes}:${seconds}`;
};

export const UserPromptConfirmationPanel: FC<
  {
    onConfirmation: () => void;
    onRejection: () => void;
  } & UserPromptConfirmationPanelProps
> = ({
  prompt,
  title,
  text,
  confirmButtonLabel,
  rejectButtonLabel,
  onConfirmation,
  onRejection,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const refreshSession = useStore((state) => state.actions.refreshSession);
  const closeWidget = useStore((state) => state.actions.closeWidget);
  const config = useStore((state) => state.config);
  const lastUserActivityTimestamp = useStore(
    (state) => state.lastUserActivityTimestamp,
  );

  const modalRef = useRef<HTMLDivElement>(null);

  const handleConfirmation = useCallback(() => {
    onConfirmation();
    refreshSession();
  }, [refreshSession, onConfirmation]);

  const handleRejection = useCallback(() => {
    refreshSession();
    onRejection();
  }, [closeWidget, onRejection]);

  useEffect(() => {
    const modalElement = modalRef.current;
    //add any focusable HTML element you want to include to this string
    const focusableElements = modalElement?.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );

    if (!focusableElements?.length) {
      return;
    }

    const firstElement = focusableElements[0] as HTMLElement;
    const lastElement = focusableElements[
      focusableElements.length - 1
    ] as HTMLElement;

    const handleTabKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        if (event.shiftKey && document.activeElement === firstElement) {
          event.preventDefault();
          lastElement.focus();
        } else if (!event.shiftKey && document.activeElement === lastElement) {
          event.preventDefault();
          firstElement.focus();
        }
      }
    };

    modalElement?.addEventListener('keydown', handleTabKeyPress);

    return () => {
      modalElement?.removeEventListener('keydown', handleTabKeyPress);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      buttonRef.current?.focus();
    }, 100);
  }, [buttonRef?.current]);

  return (
    <section
      ref={modalRef}
      className='mchat-user-prompt-panel'
      tabIndex={-1}
      aria-modal='true'
      role='dialog'
    >
      <div className='mchat-user-prompt-panel__body'>
        <div className='mchat-user-prompt-panel__prompt'>
          <span>{prompt}</span>
        </div>
        {title && <h2>{title}</h2>}
        <div className='mchat-user-prompt-panel__content'>{text}</div>
        <div className='mchat-user-prompt-panel__actions'>
          <button
            className='mchat-user-prompt-panel-button mchat-user-prompt-panel-button--secondary'
            onClick={handleRejection}
          >
            <span>{rejectButtonLabel}</span>
          </button>
          <button
            ref={buttonRef}
            className='mchat-user-prompt-panel-button'
            onClick={handleConfirmation}
          >
            <span>{confirmButtonLabel}</span>
          </button>
        </div>
      </div>
    </section>
  );
};
