import React, { useState, type FC } from 'react';
import { type ButtonsChatBlockContent, type Direction } from '../../types';
import { ChatBubble } from '../ChatBubble';
import classNames from 'classnames';

import useStore from '../../state';
import { OptionButton } from '../common/Buttons';

interface ButtonsMessageProps extends ButtonsChatBlockContent {
  direction: Direction;
  disabled?: boolean;
}

export const ButtonsMessage: FC<ButtonsMessageProps> = ({
  direction,
  prompt,
  buttons,
  disabled = false,
}) => {
  const sendMessage = useStore((state) => state.actions.sendMessage);
  const sendAnalyticMessage = useStore(
    (state) => state.actions.sendAnalyticMessage,
  );
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(-1);

  const handleClick = (
    title: string,
    textualValue: string,
    extraValue: object | undefined,
    index: number,
  ) => {
    setSelectedButtonIndex(index);
    sendAnalyticMessage({
      type: 'widget_interaction',
      subtype: 'click',
      data: {
        source: 'button',
        label: title,
      },
      metadata: {
        index: index,
        ...extraValue
      }
    },
    {
      postpone: true
    } 
  );
    sendMessage(textualValue, false, title, extraValue);
  };

  return (
    <div
      className={classNames('mchat-message', 'mchat-buttons-message', {
        'mchat-message--in': direction === 'in',
        'mchat-message--out': direction === 'out',
      })}
    >
      {prompt && <ChatBubble direction={direction} content={prompt} />}
      <div className='mchat-option-buttons'>
        {buttons.map((btn, index) => {
          return (
            <OptionButton
              key={`${btn.textualValue}-${index}`}
              onClick={() => {
                handleClick(btn.title, btn.textualValue, btn.extraValue, index);
              }}
              disabled={selectedButtonIndex !== -1 || disabled}
              selected={selectedButtonIndex === index}
            >
              {btn.title}
            </OptionButton>
          );
        })}
      </div>
    </div>
  );
};
