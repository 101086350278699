import urljoin from 'url-join';
import axios from 'axios';
import { DialogConnector } from './DialogConnector';

interface Response {
  data: object;
}

interface BufferItem {
  payload: object,
  onThen: () => void,
  onError: () => void
}

class MChannelsRestDialogConnector extends DialogConnector {
  baseURL: string;
  mchannelsBotId: string;

  private _requestsBuffer: BufferItem[] = [];
  private _requestsCounter: number = 0;

  private _axiosInstance: any;

  constructor(params: {
    integrationKey?: string;
    baseURL: string;
    mchannelsBotId: string;
  }) {
    const { integrationKey, baseURL, mchannelsBotId } = params;

    super(integrationKey);
    this.baseURL = baseURL;
    this.mchannelsBotId = mchannelsBotId;

    this._requestsBuffer = [];
  }

  async connect(): Promise<string> {
    this._axiosInstance = axios.create({
      baseURL: this.baseURL,
    });
    return await Promise.resolve('Ready to send REST calls.');
  }

  close() {}

  refreshSession() {}

  async send(payload: object) {
    this._requestsBuffer.push({
      payload: payload,
      onThen: () => {
        this._requestsCounter -= 1; 
        Promise.resolve();
      },
      onError: () => {
        this._requestsCounter -= 1; 
        Promise.reject();
      },
    });

    this._requestsCounter += 1;
    if (this._requestsCounter === 1) {
      // process the requests one by one (shortage in maddie )
      this._processSendRequests(this._requestsBuffer.shift());
    }
  }

  _processSendRequests(item?: BufferItem) {
    if(!item) {
      return
    }

    const headers: any = {};

    if (this._integrationKey) {
      headers['X-mchat-api-key'] = this._integrationKey;
    }

    let promise;

      promise = this._axiosInstance.post(
        getMessageEndpointPath(this.baseURL, this.mchannelsBotId),
        item.payload,
        {
          headers,
        },
      );

    promise.then((response: Response) => {
      const { data } = response;
      item.onThen();
      this._processSendRequests(this._requestsBuffer.shift())
      this.emit('response', data);
    }).catch(()=> {
      item.onError()
      this._processSendRequests(this._requestsBuffer.shift())
    });
  }
}

const getMessageEndpointPath = (apiBase: string, botId: string) => {
  return urljoin(apiBase, 'bots', botId, 'message');
};

export default MChannelsRestDialogConnector;
