import urljoin from 'url-join';
import axios from 'axios';
import { DialogConnector } from './DialogConnector';

interface Response {
  data: object;
}

interface BufferItem {
  payload: object;
  onThen: () => void;
  onError: () => void;
}

class MChannelsRestDialogConnector extends DialogConnector {
  baseURL: string;
  mchannelsBotId: string;
  accountId?: string;
  botId?: string;

  private _requestsBuffer: BufferItem[] = [];
  private _requestsCounter: number = 0;

  private _axiosInstance: any;

  constructor(params: {
    integrationKey?: string;
    baseURL: string;
    mchannelsBotId: string;
    accountId?: string;
    botId?: string;
  }) {
    const { integrationKey, baseURL, mchannelsBotId, accountId, botId } =
      params;

    super(integrationKey);
    this.baseURL = baseURL;
    this.mchannelsBotId = mchannelsBotId;
    this.accountId = accountId;
    this.botId = botId;

    this._requestsBuffer = [];
  }

  async connect(): Promise<string> {
    this._axiosInstance = axios.create({
      baseURL: this.baseURL,
    });
    return await Promise.resolve('Ready to send REST calls.');
  }

  close() {}

  refreshSession() {}

  async send(payload: object) {
    this._requestsBuffer.push({
      payload: payload,
      onThen: () => {
        this._requestsCounter -= 1;
        Promise.resolve();
      },
      onError: () => {
        this._requestsCounter -= 1;
        Promise.reject();
      },
    });

    this._requestsCounter += 1;
    if (this._requestsCounter === 1) {
      // process the requests one by one (shortage in maddie )
      this._processSendRequests(this._requestsBuffer.shift());
    }
  }

  _processSendRequests(item?: BufferItem) {
    if (!item) {
      return;
    }

    const headers: any = {};

    if (this._integrationKey) {
      headers['X-mchat-api-key'] = this._integrationKey;
    }

    let promise;

    const endpoint =
      this.accountId && this.botId
        ? getBotMessageEndpointPath(
            this.baseURL,
            this.mchannelsBotId,
            this.accountId,
            this.botId,
          )
        : getMchannelsMessageEndpointPath(this.baseURL, this.mchannelsBotId);

    promise = this._axiosInstance.post(endpoint, item.payload, {
      headers,
    });

    promise
      .then((response: Response) => {
        const { data } = response;
        item.onThen();
        this._processSendRequests(this._requestsBuffer.shift());
        this.emit('response', data);
      })
      .catch((error:object) => {
        console.error(error)
        this._processSendRequests(this._requestsBuffer.shift());
      });
  }
}

const getMchannelsMessageEndpointPath = (
  apiBase: string,
  mchannelsBotId: string,
) => {
  return urljoin(apiBase, 'bots', mchannelsBotId, 'message');
};

const getBotMessageEndpointPath = (
  apiBase: string,
  mchannelsBotId: string,
  accountId: string,
  botId: string,
) => {
  return urljoin(
    apiBase,
    'engine',
    mchannelsBotId,
    'account',
    accountId,
    'bot',
    botId,
    'message',
  );
};

export default MChannelsRestDialogConnector;
