import React, { SyntheticEvent, useCallback } from 'react';
import { type FC } from 'react';
import {
  type MChatEvents,
  type HyperlinkChatBlockContent,
  type Direction,
} from '../../types';

import classNames from 'classnames';
import { HyperlinkButton } from '../common/HyperlinkButton';
import useOnLinkClick from '../../hooks/useOnLinkClick';
import useStore from '../../state';

interface HyperlinkMessageProps extends HyperlinkChatBlockContent {
  onEvent?: (event: MChatEvents) => void;
  direction: Direction;
}

export const HyperlinkMessage: FC<HyperlinkMessageProps> = ({
  url,
  text,
  direction,
  onClickAction,
  onEvent = () => {},
}) => {
  const onLinkClickHandler = useOnLinkClick();

  const onLinkClick = useCallback(
    (event: SyntheticEvent) => {
      onLinkClickHandler(url, event, onEvent, onClickAction, 'button');
    },
    [url, onClickAction, onEvent],
  );

  return (
    <div
      className={classNames('mchat-message', 'mchat-hyperlink-message', {
        'mchat-message--in': direction === 'in',
        'mchat-message--out': direction === 'out',
      })}
    >
      <HyperlinkButton url={url} onClick={onLinkClick}>
        {text}
      </HyperlinkButton>
    </div>
  );
};
