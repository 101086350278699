import { type FC, type PropsWithChildren } from 'react';

export const ChatOpener: FC<
  PropsWithChildren<{
    onClick?: () => void;
  }>
> = ({ children, onClick }) => {
  return (
    <button
      onClick={() => {
        onClick?.();
      }}
      className='mchat-opener'
    >
      <span className='mchat-opener__icon'/>
      {/* {children} */}
    </button>
  );
};
