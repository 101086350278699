import { type ReactElement, type FC } from 'react';
import {
  type RowTemplate,
  type RowTemplateItem,
  type TimestampRowTemplateItem,
} from '../types';
import get from 'lodash.get';
import useStore from '../state';
import classNames from 'classnames';
import dayjs from 'dayjs';

interface RowTemplateRendererProps {
  className?: string;
  rowTemplate: RowTemplate;
  values?: Values;
}

type Values = Record<string, string | number | boolean | object>;

const RowTemplateRenderer: FC<RowTemplateRendererProps> = ({
  rowTemplate,
  className,
  values,
}) => {
  const sessionValues = useStore((state) => state.sessionValues);
  const contextValues = values ?? {};

  const context = {
    ...contextValues,
    sessionValues,
  };

  className = classNames('mchat-flex', className);

  return (
    <div className={className}>
      {rowTemplate.map((item, index) =>
        renderRowTemplateItem(index, item, context),
      )}
    </div>
  );
};

const renderRowTemplateItem = (
  index: number,
  rowTemplateItem: RowTemplateItem,
  context: Values,
): ReactElement => {
  return (
    <span key={`item_${index}`}>
      {typeof rowTemplateItem === 'string' &&
        renderStringTemplate(rowTemplateItem, context)}
      {typeof rowTemplateItem === 'object' &&
        rowTemplateItem.type === 'timestamp' &&
        renderTimestampTemplate(rowTemplateItem, context)}
    </span>
  );
};

const renderStringTemplate = (template: string, context: Values): string => {
  return template.replace(
    /\{\{([\w.]+)\}\}/g,
    (match: string | undefined, p1: string | undefined) => {
      if (!match || !p1) {
        return '';
      }

      const delimiterIndex = p1.indexOf('.');
      const source = p1.substring(0, delimiterIndex);
      const path = p1.substring(delimiterIndex + 1, p1.length);
      if (source === 'sessionValues') {
        return get(context.sessionValues, path);
      }
    },
  );
};

const renderTimestampTemplate = (
  template: TimestampRowTemplateItem,
  context: Values,
): string => {
  return dayjs
    .utc(context.timestamp as string)
    .local()
    .format(template.format);
};

export default RowTemplateRenderer;
