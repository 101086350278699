import type React from 'react';
import { useRef, type FC, useCallback, useEffect } from 'react';
import useStore from '../state';

import AsteriskDialogConnector from '../dialog-connectors/AsteriskDialogConnector';
import { AudioState, type TargetEnvironment } from '../types';

const LISTENING_STATE_DELAY = 200;

const DEFAULT_DEV_SETTINGS = {
  sipUri: 'sip:webrtc_client@webrtc.dev.themama.cloud',
  sipUser: 'mama_obelisk',
  sipPassword: 'obelisk_pwd',
  socketUrl: 'wss://webrtc.dev.themama.cloud/ws',
};

const DEFAULT_PROD_SETTINGS = {
  sipUri: 'sip:webrtc_client@webrtc.telma.ai',
  sipUser: 'mama_obelisk',
  sipPassword: 'obelisk_pwd',
  socketUrl: 'wss://webrtc.telma.ai/ws',
};

interface AudioControllerProps {
  targetEnvironment: TargetEnvironment;
  extension: string;
  sipUri?: string;
  sipUser?: string;
  sipPassword?: string;
  socketUrl?: string;
  sessionId?: string;

  children: (
    audioState: AudioState,
    onAudioButtonClick: (currentAudioState?: AudioState) => void,
  ) => React.ReactElement;
}

export const AudioController: FC<AudioControllerProps> = ({
  children,
  targetEnvironment,
  sipUri,
  sipUser,
  sipPassword,
  socketUrl,
  sessionId,
  extension,
}) => {
  const audioState = useStore((state) => state.audioState);
  const setAudioState = useStore((state) => state.actions.setAudioState);
  const refreshSession = useStore((state) => state.actions.refreshSession);

  const audioConnector = useRef<AsteriskDialogConnector>();

  useEffect(() => {
    return () => {
      if (audioConnector.current) {
        audioConnector.current.ua.stop();
        audioConnector.current.ua.unregister({
          all: true,
        });
        setAudioState(AudioState.Active);
      }
    };
  }, []);

  // commented out as it is not supported in older browsers. The solution is now based on a new message in chatBlocks
  // useEffect(() => {
  //   let speakingSet = false;

  //   const updateState = async () => {
  //     const receivers =
  //       audioConnector.current?.currentSession?.connection?.getReceivers();

  //     if (receivers && receivers?.length > 0) {
  //       const stats = await receivers[0].getStats();

  //       stats.forEach((report) => {
  //         if (!speakingSet && report.type === 'inbound-rtp') {
  //           setAudioState(ChatInputAudioState.Speaking);
  //           speakingSet = true;
  //         }
  //       });
  //     }
  //   };

  //   if (audioConnector?.current?.audioElement) {
  //     audioConnector?.current?.audioElement.addEventListener(
  //       'timeupdate',
  //       (event) => {
  //         if (!speakingSet) {
  //           void updateState();
  //         }
  //       },
  //     );
  //   }
  // }, [audioConnector?.current?.audioElement]);

  const onRTCSessionEvent = useCallback(
    (eventName: string) => {
      switch (eventName) {
        case 'confirmed':
          setTimeout(() => {
            setAudioState(AudioState.Listening);
          }, LISTENING_STATE_DELAY);

          break;
        case 'ended':
          setAudioState(AudioState.Active);
          break;
        case 'failed':
          setAudioState(AudioState.Active);
          break;
      }
    },
    [audioConnector, setAudioState],
  );

  const handleAudioButtonClick = useCallback(
    async (currentAudioState?: AudioState) => {
      refreshSession();

      if (!audioConnector.current) {
        if (sessionId) {
          const settings = getSettingsByTargetEnvironment(targetEnvironment);
          audioConnector.current = new AsteriskDialogConnector(
            sipUri ?? settings.sipUri,
            sipUser ?? settings.sipUser,
            sipPassword ?? settings.sipPassword,
            socketUrl ?? settings.socketUrl,
            sessionId,
            extension,
          );
          audioConnector.current.onRTCSessionEvent = onRTCSessionEvent;
          await audioConnector.current.connect();
        } else {
          setAudioState(AudioState.Active);
          return;
        }
      }

      if (currentAudioState === AudioState.Active) {
        audioConnector?.current?.call();
        setAudioState(AudioState.Initiating);
      } else if (currentAudioState === AudioState.Listening) {
        setAudioState(AudioState.Processing);
      }
    },
    [audioConnector.current, setAudioState, sessionId],
  );

  // eslint-disable-next-line
  return children(audioState, handleAudioButtonClick);
};

const getSettingsByTargetEnvironment = (
  targetEnvironment: TargetEnvironment,
): {
  sipUri: string;
  sipUser: string;
  sipPassword: string;
  socketUrl: string;
} => {
  if (targetEnvironment === 'dev') {
    return DEFAULT_DEV_SETTINGS;
  } else {
    return DEFAULT_PROD_SETTINGS;
  }
};
